import React from 'react'

import {
  feedbackStyles,
} from '../../RunwayReservationsTable.module.css'

type Props = {
  feedback: string
}

export const Feedback = ({ feedback }: Props) => (
  <div className={feedbackStyles}>
    <span>{feedback}</span>
  </div>
)
