import React, { useState, useMemo } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import { Button } from '../../../Button'
import { FormItem } from '../../../Form'
import { Select, SelectOption } from '../../../Select'

import { useRoleCandidates } from '../../../../hooks/useRoleCandidates'

import { styleTitle, styleIcon, styleFooter } from './ReservationModalContent.module.css'

const ReservationModalContent = ({ profile, handleCloseModal, loading, fetchData, userData }) => {
  const [selectedPartnerRole, setSelectedPartnerRole] = useState()
  const {
    state: {
      partnerRoles,
      mappedPartnerRoles,
      roleCandidates,
    },
    actions: {
      onReserve
    }
  } = useRoleCandidates(profile.id)

  const handleReserveModalClose = () => {
    handleCloseModal()
    setSelectedPartnerRole(null)
  }

  const handleReservationConfirmed = async () => {
    await fetchData()
    handleReserveModalClose()
  }

  const handleSelectChange = value => {
    setSelectedPartnerRole(partnerRoles.find(({ id }) => id === value))
  }

  const filteredMappedPartnerRoles = useMemo(
    () => {
      const openRoleCandidates = roleCandidates?.filter(roleCandidate => !roleCandidate.closedAt)
      return mappedPartnerRoles.filter(({ value }) => {
        const hasSameRole = openRoleCandidates?.find(open => open.partnerRole.id === value)
        return (value !== profile.partnerRoleId && !hasSameRole)
      })
    },
    [mappedPartnerRoles, profile.partnerRoleId, roleCandidates])

  // On calling onReserve(...), it returns a function of type (e) => {...}
  const handleReserveProfile = onReserve({
    profile,
    partnerRole: selectedPartnerRole,
    userData,
    origin: {
      type: 'Runway',
      user: userData.username,
    }
  }, handleReservationConfirmed)

  return <>
    <h2 className={styleTitle}><ExclamationCircleOutlined className={styleIcon} /> Reserve Candidate</h2>
    <FormItem>
      <Select
        placeholder="Select a Role"
        onChange={handleSelectChange}
        showSearch
        filterOption={(input, option) => option?.children?.toLowerCase()?.includes(input?.toLowerCase())}
      >
        {filteredMappedPartnerRoles.map(({ value, label }) =>
          <SelectOption key={value} value={value}>{label}</SelectOption>
        )}
      </Select>
    </FormItem>
    <div className={styleFooter}>
      <Button disabled={loading} onClick={handleReserveModalClose}>
          Cancel
      </Button>
      <Button type="primary" onClick={handleReserveProfile}>
          Confirm
      </Button>
    </div>
  </>
}

export default ReservationModalContent
