import { request } from '../services/strapi/api'

export const getProfileStatus = id => {
  const params = {
    profile: id,
    populate: [
      'bookedCandidate.partnerRole.partner',
      'bookedCandidate.assignee'
    ]
  }
  return request.get(`/profile-statuses`, { params })
}
