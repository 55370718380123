import React from 'react'

import {
  BookOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  FileDoneOutlined,
  HourglassOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  PhoneOutlined,
} from '@ant-design/icons'

import type { ProfileStatuses } from './ProfileStatuses.types'

export const PROFILE_STATUSES: ProfileStatuses = {
  ready: {
    icon: <CheckCircleOutlined className="profile-status-icon" />,
    color: 'green',
    text: 'Ready',
    value: 'ready'
  },
  booked: {
    icon: <BookOutlined className="profile-status-icon" />,
    color: '#34bcb7',
    text: 'Booked',
    value: 'booked'
  },
  reserved: {
    icon: <FileDoneOutlined className="profile-status-icon" />,
    color: '#f3b765',
    text: 'Reserved',
    value: 'reserved'
  },
  out: {
    icon: <UsergroupDeleteOutlined className="profile-status-icon" />,
    color: '#65b7f3',
    text: 'Out',
    value: 'out'
  },
  soon: {
    icon: <ClockCircleOutlined className="profile-status-icon" />,
    color: '#a9d135',
    text: 'Soon',
    value: 'soon'
  },
  pitched: {
    icon: <UsergroupAddOutlined className="profile-status-icon" />,
    color: '#AB7AE0',
    text: 'Pitched',
    value: 'pitched'
  },
  clientInterviewed: {
    icon: <PhoneOutlined />,
    color: '#6BBCFF',
    text: 'Client Interviewed',
    value: 'clientInterviewed'
  },
  accepted: {
    icon: <CheckCircleOutlined />,
    color: '#40BF6A',
    text: 'Accepted',
    value: 'accepted'
  },
  snoozed: {
    icon: <HourglassOutlined className="profile-status-icon" />,
    color: '#E87040',
    text: 'Snoozed',
    value: 'snoozed'
  },
}
