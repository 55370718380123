import React, { useCallback, useEffect, useMemo, useState } from 'react'

import type { DefaultOptionType } from 'antd/es/select'
import type { SorterResult } from 'antd/es/table/interface'
import dayjs from 'dayjs'

import { Feedback } from './components/Feedback'
import { ReservationModal } from './components/ReservationModal/ReservationModal'
import { ReserveButton } from './components/ReserveButton/ReserveButton'
import { SkillsForNextRole } from './components/SkillsForNextRole/SkillsForNextRoleReadMode'
import { XProfiles } from './components/XProfiles'
import { QualifiedSkillsView } from '../../components/QualifiedSkillsView'
import { StatusView } from '../../components/StatusView'

import { ListFilterDropdown } from 'components/ListFilter'
import { Pagination } from 'components/Pagination'
import { ProfileNameColumn } from 'components/ProfileNameColumn'
import { ExpandedRowContent } from 'components/RunwaysScreen/RunwayReservationsTable/ExpandedRowContent'
import { useSearchFilter } from 'components/SearchFilter'
import type {
  ColumnsType,
  TablePaginationConfig,
  TableProps
} from 'components/Table'
import { Table } from 'components/Table'

import { updateProfile } from 'services/profile'

import { useRunwayReservations } from '../../hooks/useRunwayReservations'
import type { UseRunwaysReservationsParams } from '../../hooks/useRunwayReservations/useRunwayReservations.types'

import { useEntityList } from 'hooks/useEntityList'
import { useMediaQuery } from 'hooks/useMediaQuery'

import { useUser } from 'redesign/hooks/useUser'

import formatTimezoneOffset from 'utils/format-timezone'
import { sortByField } from 'utils/helpers'

import { RUNWAY_REASONS } from 'redesign/utils/constants'

import type Profile from 'redesign/types/Profile'
import type Runway from 'redesign/types/Runway'
import type { RunwayReservation } from 'redesign/types/RunwayReservation'
import type Skill from 'redesign/types/Skill'

import { breakpointSM, breakpointXS } from './ReservationsTable.module.css'

export const ReservationsTable = () => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(50)
  const [tableFilters, setTableFilters] =
    useState<UseRunwaysReservationsParams>({
      _start: (page - 1) * limit,
      _limit: limit,
      _sort: 'startAt:ASC'
    })

  const [selectedProfileData, setSelectedProfileData] = useState<Partial<Profile>>()
  const [showReserveModal, setShowReserveModal] = useState(false)
  const [selectedRunwayIdForSkills, setSelectedRunwayIdForSkills] = useState<number>()
  const [skillOptions, setSkillOptions] = useState<DefaultOptionType[]>([])
  const [selectedNextSkills, setSelectedNextSkills] = useState<number[]>([])
  const skills: Skill[] = useEntityList('/skills?confirmed=true') || []

  const { data, isLoading, refetch } = useRunwayReservations({
    ...tableFilters
  })
  const [pagination, setPagination] = useState({
    current: page,
    total: data?.total,
    pageSize: limit
  })

  const { createSearchableColumn } = useSearchFilter()
  const { data: userData } = useUser()

  useEffect(() => {
    setPagination(pagination => ({
      ...pagination,
      current: page,
      total: data?.total,
      pageSize: limit
    }))
  }, [data?.total, limit, page])

  useEffect(() => {
    if (skills.length > 0 && skillOptions.length === 0) {
      setSkillOptions(
        skills?.map(({ id, title }) => ({
          label: title,
          value: id
        }))
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillOptions.length, skills.length])

  const getExistingSkillIds = (skillsForNextRole: string) =>
    selectedNextSkills
      ? skillsForNextRole?.split(',')?.map(elem => parseInt(elem.split(':')[0]))
      : []

  const handleSaveNextSkills = async ({
    profileId,
    skillsForNextRole
  }: {
    id: number
    profileId: number
    skillsForNextRole: string
  }) => {
    const initialSkills = getExistingSkillIds(skillsForNextRole)

    const skillsToBeRemoved = initialSkills
      ?.map(skill => {
        if (!selectedNextSkills.includes(skill)) {
          return -skill
        }
        return null
      })
      .filter(skill => Boolean(skill))

    await updateProfile(profileId, {
      skillsForNextRole: [
        ...(skillsToBeRemoved || []),
        ...(selectedNextSkills || [])
      ]
    })

    setSelectedRunwayIdForSkills(undefined)
    setSelectedNextSkills([])

    refetch()
  }

  const isBreakpointXS = useMediaQuery(`(min-height: ${breakpointXS})`)
  const isBreakpointSM = useMediaQuery(`(min-height: ${breakpointSM})`)
  const tableHeight = useMemo(() => {
    if (isBreakpointSM) {
      return '65vh'
    } else if (isBreakpointXS) {
      return '45vh'
    } else {
      return '35vh'
    }
  }, [isBreakpointSM, isBreakpointXS])

  const onPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      setPage(page)
      setLimit(pageSize)
      setPagination(pagination => ({
        ...pagination,
        current: page,
        pageSize
      }))
      setTableFilters({
        ...tableFilters,
        _start: (page - 1) * pageSize,
        _limit: pageSize
      })
    },
    [tableFilters]
  )

  const onTableChange: TableProps<RunwayReservation>['onChange'] = useCallback(
    (
      page: TablePaginationConfig,
      filters: Partial<UseRunwaysReservationsParams>,
      sorter:
        | SorterResult<RunwayReservation>
        | SorterResult<RunwayReservation>[]
    ) => {
      if (Array.isArray(sorter)) {
        return
      }
      const order = sorter.order === 'ascend' ? 'ASC' : 'DESC'
      const sort = sorter.column
        ? `${sorter.column.dataIndex ?? sorter.column.key}:${order}`
        : undefined
      setPage(page.current ?? 1)
      setLimit(page.pageSize ?? 10)
      setTableFilters({
        ...tableFilters,
        ...filters,
        _sort: sort
      })
    },
    [tableFilters]
  )

  const columns: ColumnsType<Runway> = [
    createSearchableColumn({
      title: 'Name / Email',
      key: 'profileFullName',
      fixed: 'left',
      sorter: true,
      placeholder: 'Filter by Name or Email',
      width: 200,
      permanent: true,
      render: (record: RunwayReservation) => {
        const { profileId, profileFullName, profileEmail } = record
        return (
          <ProfileNameColumn
            profile={{
              profileId,
              profileFullName,
              profileEmail
            }}
            filteredValue={undefined}
          />
        )
      }
    }),
    {
      title: 'X-Profiles',
      render: props => <XProfiles {...props} />,
      width: 200,
      align: 'center'
    },
    {
      title: 'Skills for Next Role',
      width: 200,
      key: 'skillsForNextRole',
      render: (record: RunwayReservation) => (
        <SkillsForNextRole
          record={record}
          skillsLimit={4}
          selectedRunwayIdForSkills={selectedRunwayIdForSkills}
          setSelectedNextSkills={setSelectedNextSkills}
          getExistingSkillIds={getExistingSkillIds}
          setSelectedRunwayIdForSkills={setSelectedRunwayIdForSkills}
          skillOptions={skillOptions}
          selectedNextSkills={selectedNextSkills}
          handleSaveNextSkills={handleSaveNextSkills}
        />
      ),
      filterDropdown: props => (
        <ListFilterDropdown
          {...props}
          filterKey="requiredSkills"
          filters={
            skills
              ? Object.values(skills)
                .sort(sortByField('title'))
                .map(skill => ({
                  text: skill.title,
                  value: skill.id
                }))
              : []
          }
          filterMultiple={true}
        />
      )
    },
    {
      title: 'Recently used skills',
      dataIndex: 'parsedResumeData',
      key: 'qualifiedSkills',
      width: 200,
      render: value => (
        <QualifiedSkillsView parsedResumeData={value} skillsLimit={4} />
      ),
      filterDropdown: props => (
        <ListFilterDropdown
          {...props}
          filterKey="requiredSkills"
          filters={
            skills
              ? Object.values(skills)
                .sort(sortByField('title'))
                .map((skill: Skill) => ({
                  text: skill?.title,
                  value: skill?.id
                }))
              : []
          }
          filterMultiple={true}
        />
      )
    },
    {
      title: 'Status',
      dataIndex: 'profileStatus',
      key: 'profileStatus',
      width: 200,
      render: value => <StatusView value={value} />,
      align: 'center',
      sorter: true,
      permanent: true
    },
    {
      title: 'Timezone',
      dataIndex: 'profileTimezoneOffset',
      key: 'profileTimezoneOffset',
      width: 200,
      render: value => formatTimezoneOffset(value, 'UTC'),
      align: 'center',
      sorter: true
    },
    {
      title: 'Job Types',
      dataIndex: 'profilePreferredJobTypes',
      key: 'profilePreferredJobTypes',
      render: value => value?.split(',')?.join(', '),
      width: 240,
      align: 'center',
      sorter: true
    },
    {
      title: 'Runway Days Used',
      dataIndex: 'elapsed',
      key: 'elapsed',
      width: 200,
      align: 'center',
      render: value => `${value} day${value > 1 ? 's' : ''}`
    },
    {
      title: 'Runway Days Left',
      dataIndex: 'daysToEndingFromToday',
      key: 'daysToEndingFromToday',
      width: 200,
      align: 'center',
      render: (value, record) => {
        if (!record.endAt && !record.duration) {
          return 'Unlimited'
        }
        if (value > 0) {
          return `${value} day${value > 1 ? 's' : ''}`
        }
        return 'Runway Ended'
      }
    },
    {
      title: 'Reason for coming off last project',
      dataIndex: 'comingOffProjectReason',
      key: 'comingOffProjectReason',
      width: 200,
      sorter: true,
      render: comingOffProjectReason => {
        const index = RUNWAY_REASONS.findIndex(
          reason => reason.value === comingOffProjectReason
        )
        return index >= 0 ? RUNWAY_REASONS[index].label : ''
      }
    },
    {
      title: 'Internal Summary',
      dataIndex: 'summary',
      key: 'summary',
      width: 200,
      sorter: true
    },
    {
      title: 'Reserved Role',
      sorter: false,
      align: 'center',
      width: 200,
      render: ({ partnerName, partnerRoleName }) => {
        if (!partnerRoleName) {
          return null
        }

        return `${partnerName}: ${partnerRoleName}`
      }
    },
    {
      title: 'Reserved On',
      align: 'center',
      width: 200,
      render: ({ prcReservedAt, prcPitchedAt }) => {
        const reservedAt = prcReservedAt || prcPitchedAt

        if (!reservedAt) {
          return null
        }

        return dayjs(reservedAt).format('DD/MM/YYYY')
      }
    },
    {
      title: 'Reserved Until',
      dataIndex: 'prcReservedUntil',
      key: 'prcReservedUntil',
      sorter: true,
      align: 'center',
      width: 200,
      render: value => (value ? dayjs(value).format('DD/MM/YYYY') : null)
    },
    {
      title: 'Reserved Days',
      dataIndex: 'reservedDaysTotal',
      key: 'reservedDaysTotal',
      width: 200,
      render: value => value > 0 && `${value} day${value > 1 ? 's' : ''}`
    },
    {
      title: 'Team',
      dataIndex: 'partnerTeamName',
      key: 'partnerTeamName',
      width: 200,
      sorter: true,
      align: 'center'
    },
    {
      title: 'Assignee',
      dataIndex: 'assigneeUsername',
      key: 'assigneeUsername',
      width: 200,
      align: 'center',
      sorter: true
    },
    {
      title: 'Latest Partner',
      dataIndex: 'lastPartnerName',
      key: 'lastPartnerName',
      width: 200,
      align: 'center',
      sorter: true
    },
    {
      title: 'Partner Feedback',
      key: 'prcFeedback',
      width: 200,
      align: 'center',
      sorter: true,
      render: ({ prcFeedback }) => <Feedback feedback={prcFeedback} />
    },
    {
      title: 'Internal Feedback',
      key: 'internalFeedback',
      width: 200,
      align: 'center',
      sorter: true,
      render: ({ internalFeedback }) => <Feedback feedback={internalFeedback} />
    },
    {
      title: 'Rate Concern',
      dataIndex: 'hasRateConcerns',
      key: 'hasRateConcerns',
      width: 200,
      render: value => (value ? 'YES' : 'NO'),
      align: 'center',
      sorter: true
    },
    {
      title: 'Start Date',
      dataIndex: 'startAt',
      key: 'startAt',
      width: 200,
      render: value => (value ? dayjs(value).format('DD/MM/YYYY') : null),
      align: 'center',
      sorter: true,
      permanent: true
    },
    {
      title: 'End Date',
      dataIndex: 'endAt',
      key: 'endAt',
      width: 200,
      render: value => (value ? dayjs(value).format('DD/MM/YYYY') : null),
      align: 'center',
      sorter: true,
      permanent: true
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 200,
      render: record => (
        <ReserveButton
          record={record}
          setSelectedProfileData={setSelectedProfileData}
          setShowReserveModal={setShowReserveModal}
        />
      ),
      align: 'center',
      permanent: true
    }
  ]

  return (
    <>
      {showReserveModal && (
        <ReservationModal
          showReserveModal={showReserveModal}
          setShowReserveModal={setShowReserveModal}
          selectedProfileData={selectedProfileData}
          setSelectedProfileData={setSelectedProfileData}
          loading={isLoading}
          fetchData={refetch}
          userData={userData}
        />
      )}
      <Pagination onChange={onPaginationChange} {...pagination} />
      <Table
        name="runwaysReservationsTable"
        showControls
        loading={isLoading}
        columns={columns}
        pagination={false}
        scroll={{ x: 1000, y: tableHeight }}
        size="small"
        dataSource={data?.runways}
        rowKey="id"
        onChange={onTableChange}
        expandable={{
          expandedRowRender: record => (
            <ExpandedRowContent
              record={record}
              renderXProfiles={(record: RunwayReservation) => (
                <XProfiles {...record} />
              )}
              renderReserveButton={(record: RunwayReservation) => (
                <ReserveButton
                  record={record}
                  setSelectedProfileData={setSelectedProfileData}
                  setShowReserveModal={setShowReserveModal}
                />
              )}
              renderSkillsForNextRole={(record: RunwayReservation) => (
                <SkillsForNextRole
                  record={record}
                  skillsLimit={4}
                  selectedRunwayIdForSkills={selectedRunwayIdForSkills}
                  setSelectedNextSkills={setSelectedNextSkills}
                  getExistingSkillIds={getExistingSkillIds}
                  setSelectedRunwayIdForSkills={setSelectedRunwayIdForSkills}
                  skillOptions={skillOptions}
                  selectedNextSkills={selectedNextSkills}
                  handleSaveNextSkills={handleSaveNextSkills}
                />
              )}
            />
          )
        }}
      />
      <Pagination onChange={onPaginationChange} {...pagination} />
    </>
  )
}
