import React, { useState } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import { App } from 'redesign/components'

import { Button } from 'components/Button'
import { Col } from 'components/Col'
import { DatePicker } from 'components/DatePicker'
import { Form, FormItem } from 'components/Form'
import { InputNumber } from 'components/InputNumber'
import { Modal } from 'components/Modal'
import { Row } from 'components/Row'
import { Select } from 'components/Select'
import { Switch } from 'components/Switch'

import { useForm } from 'hooks/useForm'

import { mapProfilesToOptions } from 'utils/runway-helpers'

import { RUNWAY_REASONS } from 'redesign/utils/constants'

import type Runway from 'redesign/types/Runway'

import type { NewRunwayModalProps } from './NewRunwayModal.types'

import {
  switchStyles,
  activeFieldStyles,
  activeFieldLabelStyles
} from './NewRunwayModal.module.css'

export const NewRunwayForm = ({ open, isLoadingBookedProfiles, bookedProfilesData, closeModal, onCreate }: NewRunwayModalProps) => {
  const { modal: { confirm } } = App.useApp()
  const [form] = useForm<Runway>()

  const initialValues: Partial<Runway> = {}

  const isRunwayActive = initialValues?.active ?? true
  const [activeField, setActiveField] = useState(isRunwayActive)

  const onClickSave = async () => {
    try {
      await form.validateFields()
    } catch (e) {
      return
    }

    const values = form.getFieldsValue()

    if (initialValues) {
      onCreate &&
        onCreate(values, {
          onSuccess: closeModal
        })
    }
  }

  const onClickCancel = () => {
    if (!form.isFieldsTouched()) {
      return closeModal()
    }

    confirm({
      title: 'Discard unsaved changes?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DISCARD',
      cancelText: 'CANCEL',
      onOk: () => {
        form.resetFields()
        closeModal()
      }
    })
  }

  const formattedInitialValues = {
    ...initialValues,
    startAt: initialValues?.startAt && dayjs(initialValues.startAt),
    endAt: initialValues?.endAt && dayjs(initialValues.endAt),
    hasRateConcerns: initialValues?.hasRateConcerns ?? 'No'
  }

  if (!open) {
    return null
  }

  const bookedProfiles = mapProfilesToOptions(bookedProfilesData)

  return (
    <Modal width={'500px'} open={open} centered footer={null}>
      <Form
        data-testid="profileRunwayForm-form"
        layout="vertical"
        form={form}
        initialValues={formattedInitialValues}
        onFinish={onClickSave}
      >
        <div className={activeFieldStyles}>
          <span className={activeFieldLabelStyles}>Active</span>
          <FormItem name="active" label="Active" noStyle>
            <Switch
              size="small"
              checked={activeField}
              onChange={checked => setActiveField(checked)}
              className={switchStyles}
            />
          </FormItem>
        </div>

        <Row>
          <Col span={24}>
            <FormItem name="profile" label="" rules={[{ required: true }]}>
              <Select
                showSearch
                filterOption
                size="large"
                optionFilterProp="label"
                placeholder="Select Candidate"
                data-testid="select-candidate"
                options={bookedProfiles}
                loading={isLoadingBookedProfiles}
              />
            </FormItem>
          </Col>
        </Row>

        <Row justify="space-between">
          <FormItem
            name="startAt"
            label="Start Date:"
            rules={[{ required: true }]}
          >
            <DatePicker size="large" />
          </FormItem>

          <FormItem name="endAt" label="End Date:">
            <DatePicker size="large" />
          </FormItem>
        </Row>

        <Col span={24}>
          <FormItem
            name="comingOffProjectReason"
            label="Reason for coming off the project:"
            rules={[{ required: true }]}
          >
            <Select
              size="large"
              options={RUNWAY_REASONS}
              filterOption={false}
              placeholder="Select a Reason"
              data-testid="select-reason"
            />
          </FormItem>
        </Col>

        <Row justify="space-between">
          <FormItem name="hasRateConcerns" label="Rate Concerns:">
            <Select
              size="large"
              filterOption={false}
              data-testid="select-rate-concern"
              style={{ width: '75px' }}
              options={[
                {
                  label: 'Yes',
                  value: 'true'
                },
                {
                  label: 'No',
                  value: 'false'
                }
              ]}
            />
          </FormItem>

          <FormItem
            name="rate"
            label="Rate (USD/hr)"
            rules={[{ required: true }]}
          >
            <InputNumber min={1} size="large" formatter={value => `$${value}`} />
          </FormItem>
        </Row>

        <Row justify="space-evenly">
          <Button
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
          <Button onClick={onClickCancel}>Cancel</Button>
        </Row>
      </Form>
    </Modal>
  )
}
