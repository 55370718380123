import { useState, useCallback } from 'react'

import { createProfile, updateProfile } from '../../services/profile'

const useProfileForm = id => {
  const [state, setState] = useState({
    loading: false,
    error: null
  })

  const create = useCallback(async profile => {
    setState(state => ({
      ...state,
      loading: true,
      error: null
    }))

    try {
      const { data } = await createProfile(profile)
      return data
    } catch (error) {
      console.error(error.message)
      throw error
    } finally {
      setState(() => ({
        loading: false
      }))
    }
  }, [])

  const save = useCallback(
    async values => {
      setState(state => ({
        ...state,
        loading: true,
        error: null
      }))
      try {
        const { data } = await updateProfile(id, values)
        return data
      } catch (error) {
        console.error(error.message)
        throw error
      } finally {
        setState(() => ({
          loading: false
        }))
      }
    },
    [id]
  )

  return {
    ...state,
    create,
    save
  }
}

export default useProfileForm
