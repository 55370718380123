import * as React from 'react'

import { getDateInReadableFormat } from '../../utils/runway-helpers'

import { text } from './RunwayDurationColumn.module.css'

export default function RunwayDurationColumn ({ endingDate, description }) {
  return (
    <>
      {endingDate && getDateInReadableFormat(endingDate)}
      {!endingDate && <p className={text}>Not defined yet</p>}
      {description && <p className={text}>({description})</p>}
    </>
  )
}
