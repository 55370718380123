import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const listNotes = async params => {
  try {
    const response = await api.get('/notes', params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const createNote = async note => {
  try {
    const response = await api.post('/notes', note)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const updateNote = async (noteId, values) => {
  try {
    const response = await api.put(`/notes/${noteId}`, values)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const deleteNote = async noteId => {
  try {
    const response = await api.delete(`/notes/${noteId}`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
