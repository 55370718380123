import React, { useMemo, useEffect } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import { RUNWAY_REASONS } from '../../../redesign/utils/constants.ts'
import { Button } from '../../Button'
import { Col } from '../../Col'
import { DatePicker } from '../../DatePicker'
import { Form, FormItem } from '../../Form'
import { InputNumber } from '../../InputNumber'
import { message } from '../../Message'
import { Row } from '../../Row'
import { Select } from '../../Select'
import { Switch } from '../../Switch'

import { App } from 'redesign/components/App'

import { useEntity } from '../../../hooks/useEntity'
import { useForm } from '../../../hooks/useForm'

import {
  title,
  icon,
  activeRunwayFormItem,
  activeRunwaySwitch,
  container,
  actionItems
} from './RunwayForm.module.css'

function RunwayForm ({
  values = {},
  profileId,
  xhqRate,
  onCreate,
  onEdit,
  onDelete,
  close,
  isSearchCandidateVisible,
  bookedProfiles
}) {
  const { modal: { confirm } } = App.useApp()

  const { id } = values

  const [form] = useForm()

  const { save, create, destroy, isBusy, isSaving, isDeleting } = useEntity(
    'runways',
    values.id
  )

  const defaultValues = useMemo(
    () => ({
      ...values,
      startAt: values.startAt && dayjs(values.startAt),
      endAt: values.endAt && dayjs(values.endAt),
      active: values.active || true
    }),
    [values]
  )

  useEffect(() => {
    if (!onEdit) {
      form.setFieldsValue({
        ...defaultValues,
        active: true,
        rate: xhqRate
      })
    }
  }, [defaultValues, form, profileId, xhqRate, onEdit])

  const onFinish = async values => {
    values.id = id

    if (values.startAt && values.endAt) {
      const isBefore = values.startAt.isBefore(values.endAt, 'date')
      const isSame = values.startAt.isSame(values.endAt, 'date')

      if (!isBefore) {
        message.error(
          `The 'end date' for a runway cannot be defined as a date prior to the 'start date'`
        )
        return
      }

      // Validate `startAt` and `endAt` dates
      if (isSame) {
        message.error(
          `The 'end date' for a runway cannot be defined the same date of the 'start date'`
        )
        return
      }
    }

    let duration = 0
    if (values.startAt && values.endAt) {
      duration = dayjs(values.endAt).diff(dayjs(values.startAt), 'day')
    }

    values.duration = duration

    try {
      if (values.id) {
        const data = await save(values)
        if (!data) {
          message.error('Updating Runway failed')
          return
        }

        onEdit(values.id, data)

        message.success('Saved Runway')
      } else {
        if (!isSearchCandidateVisible) {
          values.profile = profileId
        }
        const data = await create(values)
        if (!data) {
          message.error('Creating Runway failed')
          return
        }

        onCreate(data)
        form.resetFields()
        message.success('Created Runway')
      }
    } catch (error) {
      message.error('Error: ' + error)
    }

    if (close) {
      close()
    }
  }

  const onClickDelete = event => {
    event.preventDefault()

    confirm({
      title: 'Permanently delete item? You can\'t undo this.',
      icon: <ExclamationCircleOutlined />,
      okText: 'DELETE',
      cancelText: 'CANCEL',
      async onOk () {
        await destroy()
        onDelete(values.id)

        message.info('Deleted Runway')
      },
      onCancel () {}
    })
  }

  const onClickCancel = () => {
    confirm({
      title: 'Discard unsaved changes?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DISCARD',
      cancelText: 'CANCEL',
      onOk () {
        form.resetFields()
        if (close) {
          close()
        }
      },
      onCancel () {}
    })
  }

  return (
    <div className={container}>
      <div className={title}>
        <ExclamationCircleOutlined className={icon} /> Create a Runway
      </div>
      <Form
        initialValues={defaultValues}
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <FormItem
          name="active"
          label="Active"
          labelCol={{ span: 4, offset: 9 }}
          className={activeRunwayFormItem}
        >
          <Switch
            size="small"
            className={activeRunwaySwitch}
            defaultChecked
            onChange={checked => {
              values.active = checked
            }}
          />
        </FormItem>

        {isSearchCandidateVisible && (
          <Row>
            <Col span={24}>
              <FormItem name="profile" label="" rules={[{ required: true }]}>
                <Select
                  showSearch
                  filterOption
                  size="large"
                  optionFilterProp="label"
                  placeholder="Select Candidate"
                  data-testid="select-candidate"
                  options={bookedProfiles}
                />
              </FormItem>
            </Col>
          </Row>
        )}

        <Row justify="space-between">
          <Col>
            <FormItem
              name="startAt"
              label="Start Date:"
              rules={[{ required: true }]}
            >
              <DatePicker size="large" />
            </FormItem>
          </Col>

          <Col>
            <FormItem name="endAt" label="End Date:">
              <DatePicker size="large" />
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <FormItem
              name="comingOffProjectReason"
              label="Reason for coming off the project:"
              rules={[{ required: true }]}
            >
              <Select
                size="large"
                options={RUNWAY_REASONS}
                filterOption={false}
                placeholder="Select a Reason"
                data-testid="select-reason"
              />
            </FormItem>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col>
            <FormItem name="hasRateConcerns" label="Rate Concerns:">
              <Select
                size="large"
                filterOption={false}
                data-testid="select-rate-concern"
                style={{ width: '75px' }}
                options={[
                  {
                    label: 'Yes',
                    value: true
                  },
                  {
                    label: 'No',
                    value: false
                  }
                ]}
              />
            </FormItem>
          </Col>
          <Col>
            <FormItem
              name="rate"
              label="Rate (USD/hr)"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={1}
                size="large"
                formatter={value => `$${value}`}
              />
            </FormItem>
          </Col>
        </Row>

        <div className={actionItems}>
          <Button onClick={onClickCancel} disabled={isBusy}>
            Cancel
          </Button>
          {onCreate && (
            <Button
              data-testid="runway-save-button"
              type="primary"
              htmlType="submit"
              loading={isSaving}
              disabled={isBusy}
            >
              Create
            </Button>
          )}
          {onDelete && (
            <Button
              danger
              onClick={onClickDelete}
              loading={isDeleting}
              disabled={isBusy}
            >
              Delete
            </Button>
          )}{' '}
        </div>
      </Form>
    </div>
  )
}

export default RunwayForm
