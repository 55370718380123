import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import qs from 'qs'

import { Pagination } from '../Pagination'
import { Table } from '../Table'

import {
  listRunwaysCosts,
  countRunways,
  getTotalProjectedCosts
} from '../../services/runways'

import { useDynamicTable } from '../../hooks/useDynamicTable'

import { createProfileNameColumn } from '../../utils/profile-name-column'
import {
  getLatestXhqRateFromProfileOrXhqLink,
  getStartAndEndDatesOfAMonth,
  getProjectedWorkingDaysInAMonth,
  getInDaysFormat,
  getInUSDPerHourFormat,
  getProjectedCostsInAMonth,
  getRunwayWithExtraInfo,
  getTotalProjectedCostsParams
} from '../../utils/runway-helpers'

import Filters from './RunwayCostsTableFilters'
import RunwayElapsedInfo from './RunwayElapsedInfo'
import RunwayTotalProjectedCosts from './RunwayTotalProjectedCosts'

export default function RunwayCostsTable () {
  const [selectedMonthDates, setSelectedMonthDates] = useState({})
  const [totalCosts, setTotalCosts] = useState(0)

  useEffect(() => {
    if (Object.keys(selectedMonthDates).length) {
      const getCosts = async () => {
        const { data } = await getTotalProjectedCosts(
          getTotalProjectedCostsParams(selectedMonthDates)
        )
        setTotalCosts(data)
      }

      getCosts()
    }
  }, [selectedMonthDates])

  const defaultPagination = {
    pageSize: 50,
    current: 1
  }

  const getRequestParams = params => {
    const endAt = params.endAt ? `${params.endAt}` : dayjs().format('YYYY-MM')
    const monthDates = getStartAndEndDatesOfAMonth(endAt)
    setSelectedMonthDates(monthDates)

    const query = {
      _start: params._start,
      _limit: params._limit,
      _where: {
        _or: [
          {
            // Runways that started in the selected month
            startAt_gte: monthDates.startAt,
            startAt_lte: monthDates.endAt
          },
          {
            // Runways that started in a previous month and whose duration has not yet been defined
            startAt_lte: monthDates.endAt,
            endAt_null: true
          },
          {
            // Runways that end in the selected month
            endAt_gte: monthDates.startAt,
            endAt_lte: monthDates.endAt
          },
          {
            // Runways that started in a previous month and will ends in a future month
            startAt_lte: monthDates.endAt,
            endAt_gte: monthDates.endAt
          }
        ]
      }
    }

    return {
      params: query,
      paramsSerializer: param => qs.stringify(param)
    }
  }

  const { data, filters, loading, setFilters, pagination, onPaginationChange } =
    useDynamicTable({
      defaultPagination,
      filterToFetchParam: {},
      fetchCount: params => countRunways(getRequestParams(params)),
      fetch: params => listRunwaysCosts(getRequestParams(params))
    })

  const dataWithExtraInfo = data.map(runway => getRunwayWithExtraInfo(runway))

  const columns = [
    createProfileNameColumn({
      dataIndex: 'profile'
    }),

    {
      title: 'XHQ rate',
      key: 'xhqRate',
      render: value => {
        const xhqrate = getLatestXhqRateFromProfileOrXhqLink(
          value.profile,
          value.xhqlink
        )
        return xhqrate
          ? getInUSDPerHourFormat(xhqrate)
          : 'Not defined or imported yet'
      },
      sorter: {
        compare: (a, b) => {
          const xhqrateA = getLatestXhqRateFromProfileOrXhqLink(
            a.profile,
            a.xhqlink
          )
          const xhqrateB = getLatestXhqRateFromProfileOrXhqLink(
            b.profile,
            b.xhqlink
          )
          return xhqrateA - xhqrateB
        }
      }
    },

    {
      title: 'Runway rate',
      dataIndex: 'rate',
      key: 'rate',
      render: value => (value ? getInUSDPerHourFormat(value) : ''),
      sorter: {
        compare: (a, b) => a.rate - b.rate
      }
    },

    {
      title: 'Elapsed',
      key: 'duration',
      defaultSortOrder: 'descend',
      render: value => <RunwayElapsedInfo runway={value} />,
      sorter: {
        compare: (a, b) => dayjs(a.endingDate).diff(b.endingDate)
      }
    },

    {
      title: 'Projected working days',
      key: 'projectedDays',
      render: value => {
        const projected = getProjectedWorkingDaysInAMonth(
          value,
          selectedMonthDates
        )
        return getInDaysFormat(projected)
      },
      sorter: {
        compare: (a, b) => {
          const projectedA = getProjectedWorkingDaysInAMonth(
            a,
            selectedMonthDates
          )
          const projectedB = getProjectedWorkingDaysInAMonth(
            b,
            selectedMonthDates
          )
          return projectedA - projectedB
        }
      }
    },

    {
      title: 'Projected costs',
      key: 'projectedCosts',
      render: value => {
        const totalCosts = getProjectedCostsInAMonth(value, selectedMonthDates)
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        }).format(totalCosts)
      },
      sorter: {
        compare: (a, b) => {
          const totalCostsA = getProjectedWorkingDaysInAMonth(
            a,
            selectedMonthDates
          )
          const totalCostsB = getProjectedWorkingDaysInAMonth(
            b,
            selectedMonthDates
          )
          return totalCostsA - totalCostsB
        }
      }
    }
  ].filter(col => Boolean(col))

  return (
    <>
      <Filters filters={filters} setFilters={setFilters} />

      <RunwayTotalProjectedCosts totalCosts={totalCosts} />
      <Pagination simple={true} onChange={onPaginationChange} {...pagination} />
      <Table
        name="runwaysCostsTable"
        showControls
        columns={columns}
        rowKey="id"
        dataSource={dataWithExtraInfo}
        loading={loading}
        pagination={false}
      />
      <Pagination simple={true} onChange={onPaginationChange} {...pagination} />
    </>
  )
}
