import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const listRoleCandidateSuggestions = async params => {
  try {
    const response = await api.get(`/role-candidate-suggestions`, { ...params })
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const hideRoleCandidateSuggestion = async id => {
  try {
    const response = await api.post(`/role-candidate-suggestions/${id}/hide`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const unHideRoleCandidateSuggestion = async id => {
  try {
    const response = await api.post(`/role-candidate-suggestions/${id}/unhide`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
