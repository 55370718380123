import * as React from 'react'

import { navigate } from '@reach/router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { Tabs, TabPane } from '../Tabs'

import { useFeatureFlags } from '../../hooks/useFeatureFlags'

import { getActiveAnchor } from '../../utils/helpers'

import ActiveRunwaysListTable from './ActiveRunwaysListTable'
import RunwayCostsTable from './RunwayCostsTable'
import { RunwayReservationsTable } from './RunwayReservationsTable'

const queryClient = new QueryClient()

export default function RunwaysScreen ({ authData, isUserManager }) {
  const { features } = useFeatureFlags()
  const onChangeHandler = tab => navigate(`#${tab}`)

  return (
    <QueryClientProvider client={queryClient}>
      <Tabs
        className="content"
        type="card"
        activeKey={getActiveAnchor('runways')}
        onChange={onChangeHandler}
      >
        <TabPane tab={'Active runways list'} key="runways">
          <ActiveRunwaysListTable authData={authData} />
        </TabPane>
        {isUserManager && (<TabPane tab={'Costs table'} key="costs">
          <RunwayCostsTable authData={authData} />
        </TabPane>)}
        {features?.RELEASE_RUNWAY_IMPROVEMENTS && (
          <TabPane tab={'Reservations'} key="reservations">
            <RunwayReservationsTable authData={authData} />
          </TabPane>
        )}
      </Tabs>
    </QueryClientProvider>
  )
}
