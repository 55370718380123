import React from 'react'

import type { DefaultOptionType } from 'antd/es/select'

import { Button } from 'components/Button'
import { Select } from 'components/Select'

import type { RunwayReservation } from 'redesign/types/RunwayReservation'

type SkillsForNextRoleEditModeProps = {
  record: RunwayReservation
  skillOptions?: DefaultOptionType[]
  selectedNextSkills: number[]
  setSelectedNextSkills: React.Dispatch<React.SetStateAction<number[]>>
  handleSaveNextSkills: ({ profileId, skillsForNextRole }: {
    id: number;
    profileId: number;
    skillsForNextRole: string;
}) => Promise<void>
}

export const SkillsForNextRoleEditMode = ({
  record,
  skillOptions,
  selectedNextSkills,
  setSelectedNextSkills,
  handleSaveNextSkills
}: SkillsForNextRoleEditModeProps) => (
  <>
    <Select
      mode="multiple"
      style={{ width: '100% ' }}
      placeholder={'Select skills(s)'}
      tokenSeparators={[',']}
      options={skillOptions}
      value={selectedNextSkills}
      optionFilterProp={'label'}
      onChange={newValue => setSelectedNextSkills(newValue)}
      allowClear
      showSearch
    />
    <Button onClick={() => handleSaveNextSkills(record)}>Save</Button>
  </>
)
