import * as React from 'react'

import { Link } from 'gatsby'

import { usePartnerRoleCandidates } from 'redesign/hooks/usePartnerRoleCandidates'

import { textStyles } from './RunwayConsideredForColumn.module.css'

export default function RunwayConsideredForColumn ({ profile }) {
  const { data: roleCandidates } = usePartnerRoleCandidates({
    enabled: Boolean(profile.id),
    params: { profile: profile.id }
  })

  if (!roleCandidates.length) {
    return null
  }

  const reservedRoleCandidate = roleCandidates.find(
    role =>
      role.reservedAt &&
      role.activeAssignees.length &&
      !role.bookedAt &&
      !role.closedAt
  )

  const { activeAssignees, partnerRole } = reservedRoleCandidate ?? {}

  const salesContact = activeAssignees?.find(
    assignee => assignee.group === 'sales'
  )

  return (
    <>
      <span className={textStyles}>{partnerRole?.partner?.name}</span>
      <Link
        to={`/role?id=${partnerRole?.id}`}
        data-testid={`${partnerRole?.id}`}
      >
        {partnerRole?.name}
      </Link>
      <span className={textStyles}>{salesContact?.username}</span>
    </>
  )
}
