import React from 'react'

import { Button } from 'components/Button'

import { PROFILE_STATUSES } from 'redesign/components/ProfileStatuses'

import type { RunwayReservation } from 'redesign/types/RunwayReservation'

import { reserveButtonStyles } from './ReserveButton.module.css'

interface ReserveButtonProps {
  record: RunwayReservation
  setSelectedProfileData: React.Dispatch<
    React.SetStateAction<Partial<any> | undefined>
  >
  setShowReserveModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReserveButton = ({
  record,
  setSelectedProfileData,
  setShowReserveModal
}: ReserveButtonProps) => {
  const { profileId, profileFullName, profileStatus, partnerRoleId } = record
  if (profileStatus === PROFILE_STATUSES.reserved.value) {
    return null
  }
  return (
    <Button
      type="primary"
      className={reserveButtonStyles}
      onClick={() => {
        setSelectedProfileData({
          id: profileId,
          fullName: profileFullName,
          partnerRoleId
        })
        setShowReserveModal(true)
      }}
    >
    Reserve
    </Button>
  )
}
