import React from 'react'

import { flattenDeep, uniq } from 'lodash'

import { Tag } from '../../../Tag'

import { styleSkillTag } from '../RunwayReservationsTable.module.css'

/**
 * Gets all the skills from parsed resume data
 *
 * @param {Object} parsedResumeData
 * @returns deep flattened array of skill objects
 */
const getAllSkillsFromParsedResumeData = parsedResumeData =>
  flattenDeep(parsedResumeData?.SkillsData.map(({ SubTaxonomies }) =>
    SubTaxonomies?.map(({ Skills }) => Skills)
  ))

const isFoundIn = (item, position) =>
  item.FoundIn?.map(section => section.Id).includes(position)

/**
 * Get skill variation tag properties
 * @param {Object} skill
 * @param {string} positionId
 * @returns array containing skill variation props for tags
 */
const getVariationTagProps = (skill, positionId) =>
  skill?.Variations?.reduce((acc, variation) => {
    if (positionId && !isFoundIn(variation, positionId)) {
      return acc
    }

    return [...acc, {
      color: 'cyan',
      name: variation.Name,
      id: variation.Id
    }]
  }, [])

/**
 * Get properties of skill tags from latest 2 positions
 * @returns unique array of skill tags
 */
const getSkillTagsLatest2Positions = (parsedResumeData, skillsLimit) => {
  const allSkills = getAllSkillsFromParsedResumeData(parsedResumeData)
  const positions = parsedResumeData?.EmploymentHistory?.Positions
  let experienceSkillTagProps = []

  // Sort the positions by StartDate in descending order and then get skills from top 2 positions
  positions
    ?.sort((a, b) => new Date(b?.StartDate?.Date) - new Date(a?.StartDate?.Date))
    .slice(0, 2).forEach(({ Id: positionId }) => {
      allSkills?.forEach(skill => {
        const variations = getVariationTagProps(skill, positionId)

        if (variations?.length > 0) {
          experienceSkillTagProps = experienceSkillTagProps.concat(variations).concat({
            color: 'blue',
            name: skill.Name,
            id: skill.Name
          })
        }
      })
    })

  if (skillsLimit) {
    experienceSkillTagProps = experienceSkillTagProps.slice(0, skillsLimit)
  }

  return uniq(experienceSkillTagProps, skillTagProp => skillTagProp.id)
}

const QualifiedSkillsView = ({ parsedResumeData, skillsLimit }) => {
  if (!parsedResumeData) {
    return null
  }

  return (
    <>
      {
        getSkillTagsLatest2Positions(parsedResumeData, skillsLimit)
          .map(tagProp => (
            <Tag key={tagProp.id} color={tagProp.color} className={styleSkillTag}>{tagProp.name}</Tag>
          ))
      }
    </>
  )
}

export default QualifiedSkillsView
