import React from 'react'

import { RoleCandidatesProvider } from 'providers/RoleCandidatesProvider'

import { Modal } from 'components/Modal'
import ProfileProvider from 'components/Profile/ProfileContext'
import { ReservationModalContent } from 'components/RunwaysScreen/RunwayReservationsTable/ReservationModalContent'

import type Profile from 'redesign/types/Profile'
import type User from 'redesign/types/User'

type Props = {
  showReserveModal: boolean
  setShowReserveModal: React.Dispatch<React.SetStateAction<boolean>>
  selectedProfileData?: Partial<Profile>
  setSelectedProfileData: React.Dispatch<React.SetStateAction<Partial<Profile> | undefined>>
  loading: boolean
  fetchData: () => void
  userData: User
}

export const ReservationModal = ({ showReserveModal, setShowReserveModal, selectedProfileData, setSelectedProfileData, loading, fetchData, userData }: Props) => {
  const handleCloseModal = () => {
    setShowReserveModal(false)
    setSelectedProfileData(undefined)
  }
  return (
    <Modal open={showReserveModal} footer={null}>
      {selectedProfileData?.id && (
        <ProfileProvider id={selectedProfileData.id.toString()}>
          <RoleCandidatesProvider partnerRoleId={undefined} profileId={selectedProfileData.id}>
            <ReservationModalContent
              profile={selectedProfileData}
              handleCloseModal={handleCloseModal}
              loading={loading}
              fetchData={fetchData}
              userData={userData}
            />
          </RoleCandidatesProvider>
        </ProfileProvider>
      )}
    </Modal>
  )
}
