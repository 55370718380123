import React from 'react'

import { navigate } from '@reach/router'

import { ActiveRunways } from '../containers/ActiveRunways'
import { CostsTable } from '../containers/CostsTable'
import { ReservationsTable } from '../containers/Reservations'

import { TabPane, Tabs } from 'components/Tabs'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { useUser } from 'redesign/hooks/useUser'

import { getActiveAnchor, roleTypes } from 'utils/helpers'

export const RunwaysPage = () => {
  const { data: authData } = useUser()
  const isUserManager = authData.role.type.toLowerCase() === roleTypes.manager

  const { features } = useFeatureFlags()
  const onChangeHandler = (tab: string) => navigate(`#${tab}`)
  return (
    <Tabs
      className="content"
      type="card"
      activeKey={getActiveAnchor('runways')}
      onChange={onChangeHandler}
    >
      <TabPane tab={'Active runways list'} key="runways">
        <ActiveRunways authData={authData} />
      </TabPane>
      {isUserManager && (<TabPane tab={'Costs table'} key="costs">
        <CostsTable />
      </TabPane>)}
      {features?.RELEASE_RUNWAY_IMPROVEMENTS && (
        <TabPane tab={'Reservations'} key="reservations">
          <ReservationsTable />
        </TabPane>
      )}
    </Tabs>
  )
}
