import React from 'react'

import dayjs from 'dayjs'

import { Col } from '../Col'
import { DatePicker } from '../DatePicker'
import { Form, FormItem } from '../Form'
import { Row } from '../Row'

import { useForm } from '../../hooks/useForm'

import {
  getStartAndEndDatesOfAMonth,
  getWorkingDaysBetweenDates
} from '../../utils/runway-helpers'

export default function Filters ({ filters = {}, setFilters }) {
  const [form] = useForm()

  const onFinish = values => {
    setFilters({
      ...filters,
      endAt: values.endAt.format('YYYY-MM')
    })
  }

  const initialValues = {
    endAt: dayjs()
  }

  const getTotalWorkingDaysForSelectedMonthMessage = endAt => {
    const selectedMonth = endAt || initialValues.endAt
    const month = getStartAndEndDatesOfAMonth(selectedMonth)
    return (
      <>
        <p>
          <b>Selected month: </b> {dayjs(selectedMonth).format('MMMM YYYY')}
        </p>
        <p>
          <b>Total working days: </b>{' '}
          {getWorkingDaysBetweenDates(month.startAt, month.endAt)} days
        </p>
      </>
    )
  }

  return (
    <>
      <Form initialValues={initialValues} form={form} onFinish={onFinish}>
        <Row gutter={[16, 16]}>
          <Col>
            <FormItem
              name="endAt"
              label="Select the month for which you want to see the costs"
              style={{ marginTop: '5px' }}
            >
              <DatePicker
                picker="month"
                allowClear={false}
                onChange={() => form.submit()}
              />
            </FormItem>

            <div
              style={{
                marginBottom: '2rem'
              }}
            >
              {getTotalWorkingDaysForSelectedMonthMessage(filters.endAt)}
            </div>
          </Col>
        </Row>
      </Form>
    </>
  )
}
