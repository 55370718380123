import React from 'react'

import { formatCosts } from '../../utils/runway-helpers'

import { container } from './RunwayTotalProjectedCosts.module.css'

export default function RunwayTotalProjectedCosts ({
  totalCosts
}) {
  const costs = formatCosts(totalCosts)

  return (
    <div className={container}>
      <b>Total projected monthly cost:</b> {costs}
    </div>
  )
}
