import React from 'react'

import type { DefaultOptionType } from 'antd/es/select'

import { SkillsForNextRoleEditMode } from '../SkillsForNextRoleEditMode'
import { SkillsForNextRoleReadMode } from '../SkillsForNextRoleReadMode'

import type { RunwayReservation } from 'redesign/types/RunwayReservation'

type SkillsForNextRoleProps = {
  record: RunwayReservation
  skillsLimit: number
  selectedRunwayIdForSkills?: number
  setSelectedNextSkills: React.Dispatch<React.SetStateAction<number[]>>
  getExistingSkillIds: (skillsForNextRole: string) => number[]
  setSelectedRunwayIdForSkills: React.Dispatch<React.SetStateAction<number | undefined>>
  skillOptions?: DefaultOptionType[]
  selectedNextSkills: number[]
  handleSaveNextSkills: ({ profileId, skillsForNextRole }: {
    id: number;
    profileId: number;
    skillsForNextRole: string;
}) => Promise<void>
}

export const SkillsForNextRole = ({
  record,
  skillsLimit,
  selectedRunwayIdForSkills,
  setSelectedNextSkills,
  getExistingSkillIds,
  setSelectedRunwayIdForSkills,
  skillOptions,
  selectedNextSkills,
  handleSaveNextSkills,
}: SkillsForNextRoleProps) => {
  if (selectedRunwayIdForSkills !== record.id) {
    return (
      <SkillsForNextRoleReadMode
        record={record}
        skillsLimit={skillsLimit}
        setSelectedNextSkills={setSelectedNextSkills}
        getExistingSkillIds={getExistingSkillIds}
        setSelectedRunwayIdForSkills={setSelectedRunwayIdForSkills}
      />
    )
  }
  return (
    <SkillsForNextRoleEditMode
      record={record}
      skillOptions={skillOptions}
      selectedNextSkills={selectedNextSkills}
      setSelectedNextSkills={setSelectedNextSkills}
      handleSaveNextSkills={handleSaveNextSkills}
    />
  )
}
