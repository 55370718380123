import React, { useCallback, useEffect, useState } from 'react'

import dayjs from 'dayjs'

import { RunwayCostsTableFilters } from '../../components/RunwayCostsTableFilters'
import { RunwayElapsedInfo } from '../../components/RunwayElapsedInfo'
import RunwayTotalProjectedCosts from '../../components/RunwayTotalProjectedCosts/RunwayTotalProjectedCosts'

import { Pagination } from 'components/Pagination'
import type { ColumnsType } from 'components/Table'
import { Table } from 'components/Table'

import type { PaginationProps } from 'redesign/components/Pagination'

import { useRunwayCosts } from '../../hooks/useRunwayCosts'
import type { UseRunwaysCostsParams } from '../../hooks/useRunwayCosts/useRunwayCosts.types'
import { useRunwayCostsCount } from '../../hooks/useRunwayCostsCount/useRunwayCostsCount'
import { useTotalProjectCosts } from '../../hooks/useTotalProjectCosts'

import { createProfileNameColumn } from 'utils/profile-name-column'
import { getInUSDPerHourFormat } from 'utils/runway-helpers'

import type Runway from 'redesign/types/Runway'

export const CostsTable = () => {
  const [selectedMonthDates, setSelectedMonthDates] = useState({
    startAt: dayjs().startOf('month').format('YYYY-MM-DD'),
    endAt: dayjs().endOf('month').format('YYYY-MM-DD')
  })
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [tableFilters, setTableFilters] = useState<UseRunwaysCostsParams>({
    _start: (page - 1) * limit,
    _limit: limit,
    _sort: 'startAt:ASC',
    ...selectedMonthDates
  })

  const { data: totalProjectCostsData, isLoading: isTotalProjectCostsLoading } =
    useTotalProjectCosts({ selectedMonthDates })
  const { data: countData, isLoading: isLoadingCount } =
    useRunwayCostsCount(tableFilters)
  const { data, isLoading } = useRunwayCosts(tableFilters)
  const [pagination, setPagination] = useState({
    current: page,
    total: countData,
    pageSize: limit
  })

  useEffect(() => {
    setPagination(pagination => ({
      ...pagination,
      current: page,
      total: countData,
      pageSize: limit
    }))
  }, [countData, limit, page])

  const onFiltersChange = useCallback(
    (filters: { startAt: string; endAt: string }) => {
      setSelectedMonthDates({
        ...filters
      })
      setTableFilters({
        ...tableFilters,
        ...filters
      })
    },
    [tableFilters]
  )

  const onPaginationChange: PaginationProps['onChange'] = useCallback(
    (page: number, pageSize: number) => {
      setPage(page)
      setLimit(pageSize)
      setPagination(pagination => ({
        ...pagination,
        current: page,
        pageSize
      }))
      setTableFilters({
        ...tableFilters,
        _start: (page - 1) * pageSize,
        _limit: pageSize
      })
    },
    [tableFilters]
  )

  const columns: ColumnsType<Runway> = [
    createProfileNameColumn({
      dataIndex: 'profile'
    }),

    {
      title: 'XHQ rate',
      key: 'xhqRate',
      render: value =>
        value
          ? getInUSDPerHourFormat(value.xhqRate)
          : 'Not defined or imported yet',
      sorter: {
        compare: (a, b) => a.xhqRate - b.xhqRate
      }
    },

    {
      title: 'Runway rate',
      dataIndex: 'rate',
      key: 'rate',
      render: value => (value ? getInUSDPerHourFormat(value) : ''),
      sorter: {
        compare: (a, b) => a.rate - b.rate
      }
    },

    {
      title: 'Elapsed',
      key: 'duration',
      defaultSortOrder: 'descend',
      render: value => <RunwayElapsedInfo runway={value} />,
      sorter: {
        compare: (a, b) => dayjs(a.endingDate).diff(b.endingDate)
      }
    },

    {
      title: 'Projected working days',
      key: 'projectedDays',
      render: value => value.projectedDays,
      sorter: {
        compare: (a, b) => a.projectedDays - b.projectedDays
      }
    },

    {
      title: 'Projected costs',
      key: 'projectedCosts',
      render: value =>
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        }).format(value.projectedCosts),
      sorter: {
        compare: (a, b) => a.projectedCosts - b.projectedCosts
      }
    }
  ]

  return (
    <>
      <RunwayCostsTableFilters
        filters={selectedMonthDates}
        setFilters={onFiltersChange}
      />

      <RunwayTotalProjectedCosts totalCosts={totalProjectCostsData} />
      <Pagination onChange={onPaginationChange} {...pagination} />
      <Table
        name="runwaysCostsTable"
        showControls
        columns={columns}
        rowKey="id"
        dataSource={data}
        loading={isLoading || isLoadingCount || isTotalProjectCostsLoading}
        pagination={false}
      />
      <Pagination onChange={onPaginationChange} {...pagination} />
    </>
  )
}
