import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const listRunways = async params => {
  try {
    return await api.get('/runways', params)
  } catch (e) {
    handleThrowError(e)
  }
}

export const listRunwayReservationsV2 = async params => {
  try {
    return await api.get('/v2/runway-reservations', params)
  } catch (e) {
    handleThrowError(e)
  }
}

export const listRunwayReservations = async params => {
  try {
    return await api.get('/runway-reservations', params)
  } catch (e) {
    handleThrowError(e)
  }
}

export const listRunwaysCosts = async params => {
  try {
    return await api.get('/runways/costs', params)
  } catch (e) {
    handleThrowError(e)
  }
}

export const listRunwaysCostsV2 = async params => {
  try {
    return await api.get('/runways/v2/costs', params)
  } catch (e) {
    handleThrowError(e)
  }
}

export const countRunways = async params => {
  try {
    return await api.get('/runways/count', params)
  } catch (e) {
    handleThrowError(e)
  }
}

export const updateRunway = async (runwayId, values) => {
  try {
    return await api.put(`/runways/${runwayId}`, values)
  } catch (e) {
    handleThrowError(e)
  }
}

export const createRunwayNotification = async (profileId, values) => {
  try {
    return await api.post(`/runways/${profileId}/notify-extension`, values)
  } catch (e) {
    handleThrowError(e)
  }
}

export const createRunway = async runway => {
  try {
    return await api.post(`/runways`, runway)
  } catch (e) {
    handleThrowError(e)
  }
}

export const deleteRunway = async runwayId => {
  try {
    return await api.delete(`/runways/${runwayId}`)
  } catch (e) {
    handleThrowError(e)
  }
}

export const getTotalProjectedCosts = async params => {
  try {
    return await api.get('/runway-costs', params)
  } catch (e) {
    handleThrowError(e)
  }
}
