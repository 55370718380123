import React from 'react'

import { Tag } from 'components/Tag'

import { skillTagStyles } from './SkillNextRoleTags.module.css'

type SkillNextRoleTagsProps = {
  skillsForNextRole: string
  skillsLimit: number
}

export const SkillNextRoleTags = ({ skillsForNextRole, skillsLimit }: SkillNextRoleTagsProps) => {
  if (!skillsForNextRole) {
    return null
  }

  let skills = skillsForNextRole.split(',')

  if (skillsLimit) {
    skills = skills.slice(0, skillsLimit)
  }
  return (
    <>
      {skills.map(elem => {
        const [skillId, skillTitle] = elem.split(':')
        return (
          <Tag key={skillId} className={skillTagStyles} color="blue">
            {skillTitle}
          </Tag>
        )
      })}
    </>
  )
}
