import * as React from 'react'

import dayjs from 'dayjs'

import { getDateInReadableFormat, getInDaysFormat } from 'utils/runway-helpers'

type Props = {
  startAt: string | Date
}
export const RunwayStartAtColumn = ({ startAt }: Props) => {
  const today = dayjs().format('YYYY-MM-DD')
  const daysToStartFromToday = dayjs(startAt).diff(today, 'days')

  return (
    <>
      <div>{getDateInReadableFormat(startAt)}</div>
      {daysToStartFromToday > 0 && (
        <div>(Starting in {getInDaysFormat(daysToStartFromToday)})</div>
      )}
      {daysToStartFromToday === 0 && <div>(Starting today)</div>}
    </>
  )
}
