import React from 'react'

import { request as api } from '../../services/strapi/api'

import { sortByField } from '../../utils/helpers'

function useEntityList (apiPath) {
  const [data, setData] = React.useState()

  React.useEffect(() => {
    const op = apiPath.includes('?') ? '&' : '?'
    api.get(`${apiPath}${op}_limit=999`).then(res => {
      setData(res.data)
    })
  }, [apiPath])

  return data
}

function mapItem (item, labelField, valueField) {
  return {
    label: item[labelField],
    value: String(item[valueField])
  }
}

function getEntityOptions ({ list, labelField, valueField = 'id' }) {
  if (!list) {
    return undefined
  }
  let options
  if (Array.isArray(list)) {
    options = list.map(item => mapItem(item, labelField, valueField))
  }
  if (typeof list === 'object') {
    options = Object.keys(list).map(id =>
      mapItem(list[id], labelField, valueField)
    )
  }
  return options.sort(sortByField('label'))
}

export { getEntityOptions, useEntityList }
