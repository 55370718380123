import React from 'react'

import {
  label as labelStyles,
  labeledValue as labeledValueStyles
} from './LabeledValue.module.css'

type LabeledValueProps = {
  label: string
  value: string | number | React.ReactNode
}

export const LabeledValue = ({ label, value }: LabeledValueProps) => (
  <div className={labeledValueStyles}>
    <div className={labelStyles}>{label}</div>

    {value}
  </div>
)

export default LabeledValue
