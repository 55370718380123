/* eslint-disable camelcase */
import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import type { UseRunwaysCostsParams } from '../useRunwayCosts/useRunwayCosts.types'

import { countRunways } from 'services/runways'

import { getRequestParams } from '../../utils/costsUtils'

import type { UseRunwaysCostsCountReturn } from './useRunwayCosts.types'

export const useRunwayCostsCount = (params: UseRunwaysCostsParams): UseRunwaysCostsCountReturn => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _start, _limit, _sort, ...newParams } = params
  const { data: runwayCostsData, isLoading, isSuccess } = useQuery({
    queryKey: ['runwaysCostsCount', newParams],
    queryFn: () => countRunways(getRequestParams(newParams)),
  })

  const data = useMemo(() => runwayCostsData?.data || 0, [runwayCostsData])

  return {
    data,
    isLoading,
    isSuccess,
  }
}
