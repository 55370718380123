import { useMemo } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { message } from 'components/Message'

import { listRunways, createRunway as createRunwayService } from 'services/runways'

import type { MutationOptions } from 'redesign/types/Mutation'
import type Runway from 'redesign/types/Runway'

import type { UseRunwaysProps, UseRunwaysReturn } from './useRunways.types'

export const useRunways = (params: UseRunwaysProps): UseRunwaysReturn => {
  // Get
  const { data: runwayData, isLoading, isSuccess, refetch } = useQuery<AxiosResponse<Runway[]>, AxiosError>({
    queryKey: ['runways', params],
    queryFn: () => listRunways({
      params: {
        populate: [
          'profile.profileStatus',
        ],
        ...params
      }
    }),
  })

  const data = useMemo(() => runwayData?.data || [], [runwayData])

  // Post
  const createMutation = useMutation<AxiosResponse, AxiosError, Runway>({
    mutationFn: createRunwayService,
    onSuccess: async () => {
      message.success('Runway created')
      await refetch()
    },
    onError: () => {
      message.error('Create Runway failed')
    }
  })

  const createRunway = (values: Runway, callbacks?: MutationOptions) => {
    createMutation.mutate(values, callbacks)
  }

  return {
    data,
    isLoading,
    isSuccess,
    refetch,
    createRunway,
  }
}
