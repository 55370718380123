/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { createContext, useEffect, useState, useCallback } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import moment from 'moment'

import { Tooltip } from '../Tooltip'

import { App } from 'redesign/components/App'

import { listExperiences } from '../../services/experiences'
import { listJobApplications } from '../../services/jobApplications'
import { listNotes } from '../../services/notes'
import {
  readProfile,
  listProfileSimilarityModels
} from '../../services/profile'
import { listRunways } from '../../services/runways'
import { listScreenings, listDraftScreenings } from '../../services/screenings'
import { advancedSearch } from '../../services/search'
import { listDraftTechEval, listTechEvals } from '../../services/techEvals'
import { listXHQLinks } from '../../services/xhqlinks'

import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { useProfileForm } from '../../hooks/useProfileForm'

import formatTimezoneOffset from '../../utils/format-timezone'
import {
  arrayToSelectOptions,
  errorResponseHandler,
  filledObjectProps,
  successResponseHandler
} from '../../utils/helpers'
import { getLatestXhqRateFromProfileOrXhqLink } from '../../utils/runway-helpers'
import {
  getLocationSelectValue,
  prepareLocationSelectValue
} from '../../utils/timezone-column'

import { formatNegativeValues } from 'redesign/utils/helpers'
import { notNumberRegex } from 'redesign/utils/numberUtils'

import type JobType from 'redesign/types/JobType'
import type Skill from 'redesign/types/Skill'

import type {
  IProfileContext,
  ProfileContextProps,
  ProfileMockedProviderProps
} from './ProfileContext.types'

export const ProfileContext = createContext<ProfileContextProps | null>(null)

const ProfileProvider: React.FC<IProfileContext> = ({ id, children }) => {
  const { modal: { confirm } } = App.useApp()
  const { features }: any = useFeatureFlags()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<any>({})

  const [selectedModels, setSelectedModels] = useState<string[]>([])
  const [loadingDatasets, setLoadingDatasets] = useState(false)
  const [similarityModels, setSimilarityModels] = useState<
    { name: string; value: never; disabled: boolean }[]
  >([])
  const [createdProfiles, setCreatedProfiles] = useState<
    Record<string, unknown>[]
  >([])
  const [xhqlink, setXhqlink] = useState<{ rate?: any }>({})
  const [xhqRate, setXhqRate] = useState<number | null>(null)

  const { create, save } = useProfileForm(id)

  const newProfile = () => {
    setData(null)
  }
  useEffect(() => {
    const fetchProfile = async () => {
      // Reset profile data to override any previous saved one
      setData({})
      setError(null)
      setLoading(true)

      try {
        const { data } = await readProfile(id, {
          params: {
            populate: [
              'resume',
              'profileSnooze',
              'profileSnooze.reviewer',
              'preferredJobTypes',
              'preferredSkills'
            ]
          }
        })

        const { country, timezoneOffset } = data
        const isValidTimezoneOffset = timezoneOffset || timezoneOffset === 0
        let location

        if (country && isValidTimezoneOffset) {
          location =
            getLocationSelectValue(country, timezoneOffset) ||
            `${country} (${formatTimezoneOffset(timezoneOffset)})`
        }

        const newData = {
          ...data,
          location,
          preferredJobTypes: arrayToSelectOptions(data.preferredJobTypes),
          preferredSkills: arrayToSelectOptions(data.preferredSkills)
        }

        setLoading(false)
        setData(newData)
      } catch (error: any) {
        console.error(error)
        setLoading(false)
        setError(error.message)
      }
    }

    if (id) {
      fetchProfile()
    }
  }, [id])

  useEffect(() => {
    const loadXHQLinks = async () => {
      if (id) {
        const { data } = await listXHQLinks({
          params: {
            profile: id
          }
        })
        if (data.length) {
          setXhqlink(data[0])
        }
      }
    }

    loadXHQLinks()
  }, [id])

  useEffect(() => {
    const rate = getLatestXhqRateFromProfileOrXhqLink(data, xhqlink)
    setXhqRate(rate)
  }, [data, xhqlink])

  const fetchExperiences = useCallback(() => {
    const params = {
      profile: id,
      populate: ['skills']
    }
    return listExperiences({ params })
  }, [id])

  const fetchScreenings = useCallback(async () => {
    const params = {
      profile: id,
      populate: [
        'skills',
        'author',
        'role_candidate',
        'role_candidate.partnerRole',
        'role_candidate.partnerRole.partner',
        'role_candidate.partnerRole.roleSkills',
        'role_candidate.partnerRole.roleSkills.skill'
      ]
    }
    const publishedScreenings = await listScreenings({ params })

    const draftScreeningsResult = await listDraftScreenings({
      params: {
        profile: id,
        populate: [
          'skills',
          'author',
          'role_candidate',
          'role_candidate.partnerRole',
          'role_candidate.partnerRole.partner',
          'role_candidate.partnerRole.roleSkills',
          'role_candidate.partnerRole.roleSkills.skill'
        ]
      }
    })

    return new Promise(resolve =>
      resolve({
        data: [...publishedScreenings?.data, ...draftScreeningsResult?.data]
      })
    )
  }, [id])

  const fetchTechEvals = useCallback(async () => {
    const params = {
      profile: id,
      populate: ['skills', 'author', 'expert']
    }

    const publishedTechEvals = await listTechEvals({ params })

    const draftResult = await listDraftTechEval({
      params: {
        profile: id
      }
    })

    return new Promise(resolve =>
      resolve({
        data: [...publishedTechEvals?.data, ...draftResult?.data]
      })
    )
  }, [id])

  const fetchNotes = useCallback(() => {
    const params = {
      profile: id,
      populate: ['tags']
    }

    return listNotes({ params })
  }, [id])

  const fetchJobApplications = useCallback(
    (customParams = {}) => {
      const params = {
        profile: id,
        populate: [
          'job',
          'reviewers',
          'booking',
          'booking.partnerRole',
          'booking.bookedBy'
        ],
        ...customParams
      }

      return listJobApplications({ params })
    },
    [id]
  )

  const fetchRunways = useCallback(() => {
    const params = {
      profile: id,
      populate: ['runways']
    }

    return listRunways({ params })
  }, [id])

  const fetchSimilarityModels = useCallback(async () => {
    try {
      const response = await listProfileSimilarityModels({
        params: { component: true }
      })
      const { data } = response
      setSimilarityModels(
        data
          // eslint-disable-next-line camelcase
          .map((item: { key: string; created_at: number; name: string }) => ({
            label: (
              <Tooltip
                title={`Model Updated: ${moment(
                  item.created_at * 1000
                ).fromNow()}`}
              >
                {item.name}
              </Tooltip>
            ),
            value: item.key,
            name: item.name
          }))
          .sort((a: any, b: any) => a.name.localeCompare(b.name))
      )
      setSelectedModels(data.map((item: { key: string }) => item.key))
    } catch (error: any) {
      console.error(`Error fetching similarity models: ${error}`)
    }
  }, [])

  const fetchSimilarProfiles = useCallback(async () => {
    if (!similarityModels.length) {
      throw new Error(
        'Could not fetch Similarity Components. Please try again later.'
      )
    }
    if (!selectedModels.length) {
      throw new Error(
        'Please select at least one Similarity Component to show results.'
      )
    }
    setLoadingDatasets(false)
    try {
      const res = await advancedSearch({
        params: {
          similar: id,
          similarity_models: selectedModels,
          _start: 0,
          _limit: 10
        }
      })
      const results = res.data.results || []
      if (
        results.length &&
        Object.hasOwnProperty.call(results[0], 'similarityComponents')
      ) {
        // Deactivating models without values
        const models = Object.keys(results[0].similarityComponents)
        similarityModels.forEach(model => {
          if (
            !models.includes(model.name) &&
            selectedModels.includes(model.value)
          ) {
            model.disabled = true
          }
        })
        setSimilarityModels(
          similarityModels.sort((a, b) => a.name.localeCompare(b.name))
        )

        // Removing disabled models from selected models
        const enabledModels: string[] = similarityModels
          .filter(model => !model.disabled)
          .map(model => model.value)
        const selectedEnabledModels = selectedModels.filter(model =>
          enabledModels.includes(model)
        )
        if (selectedModels.length !== selectedEnabledModels.length) {
          setSelectedModels(selectedEnabledModels)
        }
      }
      return { data: res.data.results }
    } catch (error: any) {
      let message = error.message
      if (Object.hasOwnProperty.call(error, 'response')) {
        switch (error.response.status) {
          case 404:
            setSelectedModels([])
            message =
              'This profile has not yet been processed by the ML system. Please try again later.'
            break
          case 412:
            message = `This profile doesn't have enough data to do the comparisons.`
            break
          case 425:
            setLoadingDatasets(true)
            setTimeout(fetchSimilarProfiles, 60000)
            return
          default:
            return
        }
      }

      // Disabling and unselecting all models
      const enabledModels = similarityModels.filter(model => !model.disabled)
      if (enabledModels.length) {
        setSimilarityModels(
          similarityModels.map(model => ({ ...model, disabled: true }))
        )
      }

      throw new Error(message)
    }
    // Removing the dependency of similarityModels to avoid the request
    // being done 2 times after fetchSimilarityModels method execution
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedModels])

  const setLocationCountryTimezoneOffset = useCallback((allValues: any) => {
    const { location } = allValues
    const { country, timezone } = prepareLocationSelectValue(location)
    return {
      ...allValues,
      country,
      timezoneOffset: timezone
    }
  }, [])

  const setMinRate = useCallback((allValues: any) => {
    const { minRate: minRateValue } = allValues
    const sanitizedMinRate =
      typeof minRateValue === 'string'
        ? minRateValue.replace(notNumberRegex, '')
        : minRateValue
    const minRate = minRateValue
      ? Number.parseInt(sanitizedMinRate, 10)
      : undefined
    return {
      ...allValues,
      minRate
    }
  }, [])

  const onFinish = (callBack: any) => async (sentValues: any) => {
    let allValues = { ...data, ...sentValues }
    // Location fields require extra handling
    allValues = setLocationCountryTimezoneOffset(allValues)

    // Handle minRate field to cast it to number type if appropriate
    allValues = setMinRate(allValues)

    // Preserve only filled in fields and skip the rest
    allValues = filledObjectProps(allValues)

    // Handle cases where 'xteamEmail' is empty, to set it to NULL
    allValues.xteamEmail =
      allValues.xteamEmail === '' ? null : allValues.xteamEmail

    // Handle profileSnooze object returned by query param populate
    if (allValues.profileSnooze instanceof Object) {
      allValues.profileSnooze = allValues.profileSnooze.id || undefined
    }

    // Handle unselected options to be sent as negative values
    if (data?.preferredJobTypes && data?.preferredSkills) {
      allValues.preferredJobTypes = formatNegativeValues<JobType>(
        allValues.preferredJobTypes,
        data.preferredJobTypes
      )

      allValues.preferredSkills = formatNegativeValues<Skill>(
        allValues.preferredSkills,
        data.preferredSkills
      )
    }

    // Decides what function to use to update the profile
    const func = id ? save : create

    // After the first edit, profileStatus becomes a full object instead of an id, causing an error during profile update
    if (allValues?.profileStatus?.id) {
      allValues.profileStatus = allValues.profileStatus.id
    }

    try {
      const savedValues = await func(allValues)
      setData({
        ...allValues,
        ...savedValues,
        preferredJobTypes: arrayToSelectOptions(savedValues.preferredJobTypes),
        preferredSkills: arrayToSelectOptions(savedValues.preferredSkills)
      })
      setXhqRate(savedValues.xhqRate)
      successResponseHandler(`${id ? 'Saved' : 'Created'} profile`)
      if (!id) {
        setCreatedProfiles(profiles => [...profiles, savedValues])
      }
      if (callBack) {
        callBack()
      }
    } catch (error: any) {
      console.error({ error })
      errorResponseHandler(error)
    }
  }

  const onCancel = (form: any, callBack: any) => {
    confirm({
      title: 'Discard unsaved changes?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DISCARD',
      cancelText: 'CANCEL',
      onOk () {
        form.resetFields()
        if (callBack) {
          callBack()
        }
      }
    })
  }

  if (loading) {
    return <div>Loading Profile (id:{id})...</div>
  }

  if (error) {
    return <div style={{ color: 'red' }}>Error: {error}</div>
  }

  const contextValue: ProfileContextProps = {
    id,
    loading,
    error,
    data,
    setLoading,
    setError,
    setData,
    features,
    newProfile,
    fetchExperiences,
    fetchScreenings,
    fetchTechEvals,
    fetchNotes,
    fetchJobApplications,
    fetchSimilarityModels,
    fetchSimilarProfiles,
    fetchRunways,
    selectedModels,
    loadingDatasets,
    similarityModels,
    setSelectedModels,
    setLoadingDatasets,
    setSimilarityModels,
    onFinish,
    onCancel,
    createdProfiles,
    setCreatedProfiles,
    xhqlink,
    xhqRate
  }
  return (
    <ProfileContext.Provider value={contextValue}>
      {children}
    </ProfileContext.Provider>
  )
}

// Provider used in tests, receive context mock as props
export const ProfileMockedProvider: React.FC<ProfileMockedProviderProps> = ({
  children,
  mock
}) => <ProfileContext.Provider value={mock}>{children}</ProfileContext.Provider>

export default ProfileProvider
