import React from 'react'

import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

import { Col } from 'components/Col'
import { DatePicker } from 'components/DatePicker'
import { Form, FormItem } from 'components/Form'
import { Row } from 'components/Row'

import { useForm } from 'hooks/useForm'

import {
  getStartAndEndOfMonth,
  getWorkingDaysBetweenDatesDayjs,
} from 'utils/runway-helpers'

type RunwayCostsTableFiltersProps = {
  filters: {
    startAt: string
    endAt: string
  }
  setFilters: (filters: {
    startAt: string
    endAt: string
  }) => void

}

export const RunwayCostsTableFilters = ({ filters, setFilters }: RunwayCostsTableFiltersProps) => {
  const [form] = useForm()

  const onFinish = (values: { endAt: Dayjs }) => {
    const dates = getStartAndEndOfMonth(values?.endAt?.startOf('month')?.toISOString())
    setFilters({
      ...filters,
      ...dates,
    })
  }

  const initialValues = {
    endAt: dayjs()
  }

  const getTotalWorkingDaysForSelectedMonthMessage = (selectedEndAt: string) => {
    const { startAt, endAt } = getStartAndEndOfMonth(selectedEndAt)
    return (
      <>
        <p>
          <b>Selected month: </b> {dayjs(selectedEndAt).format('MMMM YYYY')}
        </p>
        <p>
          <b>Total working days: </b>{' '}
          {getWorkingDaysBetweenDatesDayjs(startAt, endAt)} days
        </p>
      </>
    )
  }

  return (
    <Form initialValues={initialValues} form={form} onFinish={onFinish}>
      <Row gutter={[16, 16]}>
        <Col>
          <FormItem
            name="endAt"
            label="Select the month for which you want to see the costs"
            style={{ marginTop: '5px' }}
          >
            <DatePicker
              picker="month"
              allowClear={false}
              onChange={() => form.submit()}
            />
          </FormItem>

          <div
            style={{
              marginBottom: '2rem'
            }}
          >
            {getTotalWorkingDaysForSelectedMonthMessage(filters?.endAt)}
          </div>
        </Col>
      </Row>
    </Form>
  )
}
