import React from 'react'

import moment from 'moment'

import { RUNWAY_REASONS } from '../../../redesign/utils/constants'
import { ListFilterDropdown } from '../../ListFilter'
import { ProfileNameColumn } from '../../ProfileNameColumn'

import formatTimezoneOffset from '../../../utils/format-timezone'
import { sortByField } from '../../../utils/helpers'

import { QualifiedSkillsView } from './QualifiedSkillsView'
import { StatusView } from './StatusView'

export const getColumns = ({
  createSearchableColumn,
  renderXProfiles,
  renderReserveButton,
  renderSkillsForNextRole,
  renderPartnerFeedback,
  renderInternalFeedback,
  skills
}) => [
  createSearchableColumn({
    title: 'Name / Email',
    key: 'profileFullName',
    fixed: 'left',
    sorter: true,
    placeholder: 'Filter by Name or Email',
    width: 200,
    permanent: true,
    render: ({ profileId, profileFullName, profileEmail }) => (
      <ProfileNameColumn
        profile={{
          profileId,
          profileFullName,
          profileEmail
        }}
      />
    )
  }),
  {
    title: 'X-Profiles',
    render: renderXProfiles,
    width: 200,
    align: 'center'
  },
  {
    title: 'Skills for Next Role',
    width: 200,
    key: 'skillsForNextRole',
    render: value => renderSkillsForNextRole(value, 4),
    filterDropdown: props => (
      <ListFilterDropdown
        {...props}
        filterKey="requiredSkills"
        filters={
          skills
            ? Object.values(skills)
              .sort(sortByField('title'))
              .map(skill => ({
                text: skill.title,
                value: skill.id
              }))
            : []
        }
        filterMultiple={true}
      />
    )
  },
  {
    title: 'Recently used skills',
    dataIndex: 'parsedResumeData',
    key: 'qualifiedSkills',
    width: 200,
    render: value => (
      <QualifiedSkillsView parsedResumeData={value} skillsLimit={4} />
    ),
    filterDropdown: props => (
      <ListFilterDropdown
        {...props}
        filterKey="requiredSkills"
        filters={
          skills
            ? Object.values(skills)
              .sort(sortByField('title'))
              .map(skill => ({
                text: skill.title,
                value: skill.id
              }))
            : []
        }
        filterMultiple={true}
      />
    )
  },
  {
    title: 'Status',
    dataIndex: 'profileStatus',
    key: 'profileStatus',
    width: 200,
    render: value => <StatusView value={value} />,
    align: 'center',
    sorter: true,
    permanent: true
  },
  {
    title: 'Timezone',
    dataIndex: 'profileTimezoneOffset',
    key: 'profileTimezoneOffset',
    width: 200,
    render: value => formatTimezoneOffset(value, 'UTC'),
    align: 'center',
    sorter: true
  },
  {
    title: 'Job Types',
    dataIndex: 'profilePreferredJobTypes',
    key: 'profilePreferredJobTypes',
    render: value => value?.split(',')?.join(', '),
    width: 240,
    align: 'center',
    sorter: true
  },
  {
    title: 'Runway Days Used',
    dataIndex: 'elapsed',
    key: 'elapsed',
    width: 200,
    align: 'center',
    render: value => `${value} day${value > 1 ? 's' : ''}`
  },
  {
    title: 'Runway Days Left',
    dataIndex: 'daysToEndingFromToday',
    key: 'daysToEndingFromToday',
    width: 200,
    align: 'center',
    render: (value, record) => {
      if (!record.endAt && !record.duration) {
        return 'Unlimited'
      }
      if (value > 0) {
        return `${value} day${value > 1 ? 's' : ''}`
      }
      return 'Runway Ended'
    }
  },
  {
    title: 'Reason for coming off last project',
    dataIndex: 'comingOffProjectReason',
    key: 'comingOffProjectReason',
    width: 200,
    sorter: true,
    render: comingOffProjectReason => {
      const index = RUNWAY_REASONS.findIndex(
        reason => reason.value === comingOffProjectReason
      )
      return index >= 0 ? RUNWAY_REASONS[index].label : ''
    }
  },
  {
    title: 'Internal Summary',
    dataIndex: 'summary',
    key: 'summary',
    width: 200,
    sorter: true
  },
  {
    title: 'Reserved Role',
    sorter: false,
    align: 'center',
    width: 200,
    render: ({ partnerName, partnerRoleName }) => {
      if (!partnerRoleName) {
        return null
      }

      return `${partnerName}: ${partnerRoleName}`
    }
  },
  {
    title: 'Reserved On',
    align: 'center',
    width: 200,
    render: ({ prcReservedAt, prcPitchedAt }) => {
      const reservedAt = prcReservedAt || prcPitchedAt

      if (!reservedAt) {
        return null
      }

      return moment(reservedAt).format('DD/MM/YYYY')
    }
  },
  {
    title: 'Reserved Until',
    dataIndex: 'prcReservedUntil',
    key: 'prcReservedUntil',
    sorter: true,
    align: 'center',
    width: 200,
    render: value => (value ? moment(value).format('DD/MM/YYYY') : null)
  },
  {
    title: 'Reserved Days',
    dataIndex: 'reservedDaysTotal',
    key: 'reservedDaysTotal',
    width: 200,
    render: value => value > 0 && `${value} day${value > 1 ? 's' : ''}`
  },
  {
    title: 'Team',
    dataIndex: 'partnerTeamName',
    key: 'partnerTeamName',
    width: 200,
    sorter: true,
    align: 'center'
  },
  {
    title: 'Assignee',
    dataIndex: 'assigneeUsername',
    key: 'assigneeUsername',
    width: 200,
    align: 'center',
    sorter: true
  },
  {
    title: 'Latest Partner',
    dataIndex: 'lastPartnerName',
    key: 'lastPartnerName',
    width: 200,
    align: 'center',
    sorter: true
  },
  {
    title: 'Partner Feedback',
    key: 'prcFeedback',
    width: 200,
    align: 'center',
    sorter: true,
    render: renderPartnerFeedback
  },
  {
    title: 'Internal Feedback',
    key: 'internalFeedback',
    width: 200,
    align: 'center',
    sorter: true,
    render: renderInternalFeedback
  },
  {
    title: 'Rate Concern',
    dataIndex: 'hasRateConcerns',
    key: 'hasRateConcerns',
    width: 200,
    render: value => (value ? 'YES' : 'NO'),
    align: 'center',
    sorter: true
  },
  {
    title: 'Start Date',
    dataIndex: 'startAt',
    key: 'startAt',
    width: 200,
    render: value => (value ? moment(value).format('DD/MM/YYYY') : null),
    align: 'center',
    sorter: true,
    permanent: true
  },
  {
    title: 'End Date',
    dataIndex: 'endAt',
    key: 'endAt',
    width: 200,
    render: value => (value ? moment(value).format('DD/MM/YYYY') : null),
    align: 'center',
    sorter: true,
    permanent: true
  },
  {
    title: 'Actions',
    key: 'actions',
    width: 200,
    render: renderReserveButton,
    align: 'center',
    permanent: true
  }
]
