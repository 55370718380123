import * as React from 'react'

import { getDateInReadableFormat } from 'utils/runway-helpers'

import type { RunwayDurationColumnProps } from './RunwayDurationColumn.types'

import { textStyles } from './RunwayDurationColumn.module.css'

export const RunwayDurationColumn = ({ endingDate, description }: RunwayDurationColumnProps) => (
  <>
    {endingDate && getDateInReadableFormat(endingDate)}
    {!endingDate && <p className={textStyles}>Not defined yet</p>}
    {description && <p className={textStyles}>({description})</p>}
  </>
)
