import React from 'react'

import { profileStatuses } from '../../../ProfileStatusTag/constants'
import { Tag } from '../../../Tag'

import * as styleTags from '../../../../components/ProfileStatusTag/StatusTag.module.css'

const StatusView = ({ value }) => {
  const statusText = value || profileStatuses.ready.value

  return (<Tag key={statusText} className={styleTags[statusText]} >
    {profileStatuses[statusText].icon}
        &nbsp;
    {profileStatuses[statusText].text}
  </Tag>)
}

export default StatusView
