import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { getTotalProjectedCosts } from 'services/runways'

import { getTotalProjectedCostsParams } from 'utils/runway-helpers'

import type { UseTotalProjectCostsParams, UseTotalProjectCostsReturn } from './useTotalProjectCosts.types'

export const useTotalProjectCosts = ({ selectedMonthDates }: UseTotalProjectCostsParams): UseTotalProjectCostsReturn => {
  const { data: runwayData, isLoading, isSuccess, refetch } = useQuery({
    queryKey: ['totalProjectCosts', selectedMonthDates],
    queryFn: () => getTotalProjectedCosts(
      getTotalProjectedCostsParams(selectedMonthDates)
    ),
    enabled: Boolean(selectedMonthDates.startAt && selectedMonthDates.endAt),
  })

  const data = useMemo(() => runwayData?.data as number || 0, [runwayData])
  return {
    data,
    isLoading,
    isSuccess,
    refetch,
  }
}
