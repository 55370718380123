import * as React from 'react'

import {
  getDateInReadableFormat,
  getElapsedDaysOutOfDurationFormat
} from '../../utils/runway-helpers'

import { text, divider } from './RunwayElapsedInfo.module.css'

export default function RunwayElapsedInfo ({ runway }) {
  return (
    <>
      <p className={text}>
        <b>Start:</b> {getDateInReadableFormat(runway.startAt)}
      </p>

      <p className={text}>
        <b>End:</b>{' '}
        {runway.endingDate && getDateInReadableFormat(runway.endingDate)}
        {!runway.endingDate && `Not defined yet`}
      </p>

      <div className={divider}></div>

      <p className={text}>
        {getElapsedDaysOutOfDurationFormat(
          runway.elapsed,
          runway.endingDuration
        )}
      </p>

      {runway.description && <p className={text}>({runway.description})</p>}
    </>
  )
}
