import React from 'react'

import { EditOutlined } from '@ant-design/icons'

import { SkillNextRoleTags } from '../SkillNextRoleTags'

import type { RunwayReservation } from 'redesign/types/RunwayReservation'

import {
  nextRoleSkillBlockStyles,
  editIconStyles,
} from '../../RunwayReservationsTable.module.css'

type SkillsForNextRoleReadModeProps = {
  record: RunwayReservation
  skillsLimit: number
  setSelectedNextSkills: React.Dispatch<React.SetStateAction<number[]>>
  getExistingSkillIds: (skillsForNextRole: string) => number[]
  setSelectedRunwayIdForSkills: React.Dispatch<React.SetStateAction<number | undefined>>
}

export const SkillsForNextRoleReadMode = ({ record, skillsLimit, setSelectedNextSkills, getExistingSkillIds, setSelectedRunwayIdForSkills }: SkillsForNextRoleReadModeProps) => {
  const { id, skillsForNextRole } = record
  return (
    <div className={nextRoleSkillBlockStyles}>
      <div>
        <SkillNextRoleTags skillsForNextRole={skillsForNextRole as string} skillsLimit={skillsLimit} />
      </div>
      <EditOutlined className={editIconStyles} onClick={() => {
        setSelectedNextSkills(
          getExistingSkillIds(skillsForNextRole)
        )
        setSelectedRunwayIdForSkills(id)
      }} />
    </div>
  )
}
