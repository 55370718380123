import React from 'react'

import { AuthConsumer } from '../providers/AuthProvider'
import { RootComponent } from 'redesign/app/RootComponent'

import Layout from '../components/Layout'
import RunwaysScreen from '../components/RunwaysScreen'
import SEO from '../components/SEO'

import { App } from 'redesign/components/App'

import { RunwaysPage as NewRunwaysPage } from 'redesign/modules/Runways'

import { useFeatureFlags } from '../hooks/useFeatureFlags'

import { roleTypes } from '../utils/helpers'

const RunwaysPage = () => {
  const { features } = useFeatureFlags()
  const isNewRunwaysPageEnabled = Boolean(features?.RELEASE_RUNWAYS_PAGE_REFACTORING)
  if (!isNewRunwaysPageEnabled) {
    return (
      <Layout menuSelectedKeys={['runways']}>
        <SEO title="Runways" />

        <AuthConsumer>
          {({ isLoading, data }) => {
            if (isLoading) {
              return <div>Loading...</div>
            }
            if (!data) {
              return <div>Not logged in</div>
            }
            const isUserManager = data.role.type.toLowerCase() === roleTypes.manager
            return (
              <RunwaysScreen authData={data} isUserManager={isUserManager} />
            )
          }}
        </AuthConsumer>
      </Layout>
    )
  }
  return (
    <App>
      <RootComponent menuSelectedKeys={['runways']}>
        <NewRunwaysPage />
      </RootComponent>
    </App>
  )
}

export default RunwaysPage
