import React, { useCallback, useEffect, useState } from 'react'

import type { SorterResult } from 'antd/es/table/interface'
import dayjs from 'dayjs'

import { RunwayConsideredForColumn } from '../../components/RunwayConsideredForColumn'
import { RunwayDurationColumn } from '../../components/RunwayDurationColumn'
import { RunwayStartAtColumn } from '../../components/RunwayStartAtColumn'
import { NewRunwayForm } from '../components/NewRunwayModal/NewRunwayModal'

import { Button } from 'components/Button'
import { Pagination as PaginationComponent } from 'components/Pagination'
import {
  Table
} from 'components/Table'
import type {
  ColumnsType,
  TablePaginationConfig,
  TableProps,
} from 'components/Table'

import type { PaginationProps } from 'redesign/components/Pagination'

import { useRunwaysCount } from '../../hooks/useRunwayCount'
import { useRunways } from '../../hooks/useRunways'
import type { UseRunwaysProps } from '../../hooks/useRunways/useRunways.types'

import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { usePartnerRoleCandidates } from 'redesign/hooks/usePartnerRoleCandidates'

import { roleTypes } from 'utils/helpers'
import { createProfileNameColumn } from 'utils/profile-name-column'

import type { MutationOptions } from 'redesign/types/Mutation'
import type Profile from 'redesign/types/Profile'
import type Runway from 'redesign/types/Runway'

import type { ActiveRunwaysProps } from './ActiveRunways.types'

import { foundTextStyles } from './ActiveRunways.module.css'

export const ActiveRunways = ({ authData }: ActiveRunwaysProps) => {
  const { data: features } = useFeatureFlags()
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const currentDate = dayjs().startOf('day').toISOString()
  const [tableFilters, setTableFilters] = useState<UseRunwaysProps>({
    startAt_lt: currentDate,
    endAt_gt: currentDate,
    _start: (page - 1) * limit,
    _limit: limit,
    _sort: 'startAt:ASC',
  })
  const [isModalOpen, setModalOpen] = useState(false)

  const { data, isLoading, createRunway } = useRunways(tableFilters)
  const { dataTotal, refetchTotal } = useRunwaysCount(tableFilters)
  const [pagination, setPagination] = useState({ current: 1, total: dataTotal ?? 0, pageSize: 10 })
  const { data: bookedProfilesData, isLoading: isLoadingRoleCandidates } = usePartnerRoleCandidates({
    params: {
      bookedAt_null: false,
      closedAt_null: true,
    },
    enabled: isModalOpen
  })

  useEffect(() => {
    setPagination(pagination => ({
      ...pagination,
      current: page,
      total: dataTotal,
      pageSize: limit
    }))
  }, [dataTotal, limit, page])

  const { role: { type: roleType } } = authData
  const canCreateRunways = roleType === roleTypes.bizdev || roleType === roleTypes.manager
  const isRunwayImprovementEnabled = features?.RELEASE_RUNWAY_IMPROVEMENTS

  const close = useCallback(() => {
    setModalOpen(false)
  }, [])

  const onCreate = async (values: Runway, callbacks?: MutationOptions) => {
    await createRunway(values, callbacks)
    setModalOpen(false)
    setPagination(pagination => ({
      ...pagination,
      total: pagination.total + 1
    }))
    refetchTotal()
  }

  const onPaginationChange: PaginationProps['onChange'] = useCallback((page: number, pageSize: number) => {
    setPage(page)
    setLimit(pageSize)
    setPagination(pagination => ({
      ...pagination,
      current: page,
      pageSize,
    }))
    setTableFilters({
      ...tableFilters,
      _start: (page - 1) * pageSize,
      _limit: pageSize,
    })
  }, [tableFilters])

  const onTableChange: TableProps<Runway>['onChange'] = useCallback(
    (
      page: TablePaginationConfig,
      filters: Partial<UseRunwaysProps>,
      sorter: SorterResult<Runway> | SorterResult<Runway>[]
    ) => {
      if (Array.isArray(sorter)) {
        return
      }
      const order = sorter.order === 'ascend' ? 'ASC' : 'DESC'
      const sort = sorter.column
        ? `${sorter.column.dataIndex ?? sorter.column.key}:${order}`
        : null
      setPage(page.current ?? 1)
      setLimit(page.pageSize ?? 10)
      setTableFilters({
        ...tableFilters,
        ...filters,
        _sort: sort,
      })
    },
    [tableFilters]
  )

  const columns: ColumnsType<Runway> = [
    createProfileNameColumn({
      dataIndex: 'profile'
    }),

    {
      title: 'Start',
      dataIndex: 'startAt',
      key: 'startAt',
      width: 180,
      render: (value: Date) => <RunwayStartAtColumn startAt={value} />,
      sorter: true
    },

    {
      title: 'Duration',
      key: 'duration',
      defaultSortOrder: 'descend',
      render: (value: Runway) => (
        <RunwayDurationColumn
          endingDate={value.endingDate}
          description={value.description}
        />
      ),
      sorter: {
        compare: (a: Runway, b: Runway) => dayjs(a.endingDate).diff(b.endingDate)
      }
    },

    {
      title: 'Internal status',
      dataIndex: 'profile',
      key: 'benchStatus',
      render: (value: Profile) => value?.benchStatus
    },

    {
      title: 'Internal summary',
      dataIndex: 'profile',
      key: 'benchSummary',
      render: (value: Profile) => value?.benchSummary
    },
    {
      title: 'Considered for',
      dataIndex: 'profile',
      key: 'profileStatus.status',
      render: (value: Profile) => <RunwayConsideredForColumn profile={value} />
    }
  ]

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <>
      {isModalOpen && (
        <NewRunwayForm
          open={isModalOpen}
          closeModal={close}
          onCreate={onCreate}
          bookedProfilesData={bookedProfilesData}
          isLoadingBookedProfiles={isLoadingRoleCandidates}
        />
      )}

      {isRunwayImprovementEnabled && canCreateRunways && (
        <Button type="primary" onClick={() => setModalOpen(true)}>
          New Runway
        </Button>
      )}
      <p className={foundTextStyles}>
        <b>{dataTotal}</b> found in total
      </p>
      <PaginationComponent onChange={onPaginationChange} {...pagination} />
      <Table
        name="activeRunwaysTable"
        showControls
        columns={columns}
        rowKey="id"
        dataSource={data}
        loading={isLoading}
        onChange={onTableChange}
        pagination={false}
      />
      <PaginationComponent onChange={onPaginationChange} {...pagination} />
    </>
  )
}
