import React, { useState, useCallback, useMemo, useEffect } from 'react'

import { ExportOutlined, EditOutlined } from '@ant-design/icons'

import { RoleCandidatesProvider } from '../../../providers/RoleCandidatesProvider'
import { Button } from '../../Button'
import { Modal } from '../../Modal'
import { Pagination } from '../../Pagination'
import ProfileProvider from '../../Profile/ProfileContext'
import { profileStatuses } from '../../ProfileStatusTag/constants'
import { useSearchFilter } from '../../SearchFilter'
import { Select } from '../../Select'
import { Table } from '../../Table'
import { Tag } from '../../Tag'

import { updateProfile, createXProfileLink } from '../../../services/profile'
import { listRunwayReservations } from '../../../services/runways'

import { useDynamicTable } from '../../../hooks/useDynamicTable'
import { useEntityList } from '../../../hooks/useEntityList'
import { useMediaQuery } from '../../../hooks/useMediaQuery'

import { getRunwayWithExtraInfo } from '../../../utils/runway-helpers'

import { ExpandedRowContent } from './ExpandedRowContent'
import { ReservationModalContent } from './ReservationModalContent'
import { getColumns } from './table-columns'

import {
  styleXProfilesBlock,
  styleNextRoleSkillBlock,
  styleEditIcon,
  styleSkillTag,
  styleFeedback,
  breakpointXS,
  breakpointSM,
} from './RunwayReservationsTable.module.css'

const RunwayReservationsTable = () => {
  const [selectedProfileData, setSelectedProfileData] = useState()
  const [showReserveModal, setShowReserveModal] = useState(false)
  const [selectedRunwayIdForSkills, setSelectedRunwayIdForSkills] = useState()
  const [skillOptions, setSkillOptions] = useState([])
  const [selectedNextSkills, setSelectedNextSkills] = useState([])
  const skills = useEntityList('/skills?confirmed=true')

  const isBreakpointXS = useMediaQuery(`(min-height: ${breakpointXS})`)
  const isBreakpointSM = useMediaQuery(`(min-height: ${breakpointSM})`)

  const tableHeight = useMemo(() => {
    if (isBreakpointSM) {
      return '60vh'
    } else if (isBreakpointXS) {
      return '45vh'
    } else {
      return '35vh'
    }
  }, [isBreakpointSM, isBreakpointXS])

  const defaultFilters = {}
  const defaultSort = {}
  const defaultPagination = {
    pageSize: 50,
    current: 1
  }

  useEffect(() => {
    if (skillOptions.length > 0) {
      return
    }

    setSkillOptions(
      skills?.map(({ id, title }) => ({
        label: title,
        value: id
      })) || []
    )
  }, [skillOptions.length, skills])

  const {
    data = {},
    setData,
    loading,
    setLoading,
    onTableChange,
    onPaginationChange,
    filters,
    pagination,
    setPagination,
    sorter,
    fetchPage,
  } = useDynamicTable({
    defaultFilters,
    defaultSort,
    defaultPagination,
    fetch: async (filters, pagination, cancelToken) => {
      const queryParams = new URLSearchParams(window.location.search)
      const reserved = queryParams.get('reserved')

      const params = {
        ...filters,
        reserved,
      }

      const response = await listRunwayReservations({ params }, pagination, cancelToken)

      const { total } = response.data

      setPagination(pagination => ({
        ...pagination,
        pageSize: filters._limit,
        total
      }))

      return response
    }
  })

  const fetchData = useCallback(async () =>
    await fetchPage({
      filters, pagination, sorter
    }), [filters, pagination, sorter, fetchPage])

  const handleOpenXProfile = useCallback(async profileId => {
    setLoading(true)

    try {
      const {
        data: { url }
      } = await createXProfileLink({ profile: profileId })
      window.open(url, '_blank')
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [setLoading])

  const getExistingSkillIds = skillsForNextRole =>
    selectedNextSkills
      ? skillsForNextRole?.split(',')?.map(elem => parseInt(elem.split(':')[0]))
      : []

  const handleSaveNextSkills = async ({ id: runwayId, profileId, skillsForNextRole }) => {
    setLoading(true)

    const initialSkills = getExistingSkillIds(skillsForNextRole)

    const skillsToBeRemoved = initialSkills?.map(skill => {
      if (!selectedNextSkills.includes(skill)) {
        return -skill
      }
      return null
    }).filter(skill => Boolean(skill))

    await updateProfile(profileId, {
      skillsForNextRole: [...(skillsToBeRemoved || []), ...(selectedNextSkills || [])]
    })

    const updatedData = { ...data }
    const dataRowId = updatedData.runways.findIndex(({ id }) => id === runwayId)
    updatedData.runways[dataRowId].skillsForNextRole =
      Array.isArray(selectedNextSkills)
        ? selectedNextSkills
          .map(skillId => `${skillId}:${skillOptions.find(({ value }) => value === skillId).label}`)
          .join(',')
        : null
    setData(updatedData)
    setSelectedRunwayIdForSkills(undefined)
    setSelectedNextSkills([])

    setLoading(false)
  }

  const renderXProfiles = record => <div className={styleXProfilesBlock} onClick={() => handleOpenXProfile(record.profileId)}>
    <ExportOutlined />
    <span>Link</span>
  </div>

  const renderReserveButton = ({ profileId, profileFullName, profileStatus, partnerRoleId }) => (
    <>
      {profileStatus !== profileStatuses.reserved.value
        ? (
          <Button type="primary" onClick={() => {
            setSelectedProfileData({
              id: profileId,
              fullName: profileFullName,
              partnerRoleId
            })
            setShowReserveModal(true)
          }}>RESERVE</Button>
        )
        : null}
    </>
  )

  const getSkillNextRoleTags = (skillsForNextRole, skillsLimit) => {
    if (!skillsForNextRole) {
      return null
    }

    let skills = skillsForNextRole.split(',')

    if (skillsLimit) {
      skills = skills.slice(0, skillsLimit)
    }

    return skills.map(elem => {
      const [skillId, skillTitle] = elem.split(':')
      return (
        <Tag key={skillId} className={styleSkillTag} color="blue">
          {skillTitle}
        </Tag>
      )
    })
  }

  const skillsForNextRoleReadMode = ({ id, skillsForNextRole }, skillsLimit) => (<div className={styleNextRoleSkillBlock}>
    <div>
      {getSkillNextRoleTags(skillsForNextRole, skillsLimit)}
    </div>
    <EditOutlined className={styleEditIcon} onClick={() => {
      setSelectedNextSkills(
        getExistingSkillIds(skillsForNextRole)
      )
      setSelectedRunwayIdForSkills(id)
    }} />
  </div>)

  const skillsForNextRoleEditMode = record => (
    <>
      <Select
        mode="multiple"
        style={{ width: '100% ' }}
        placeholder={'Select skills(s)'}
        tokenSeparators={[',']}
        options={skillOptions}
        value={selectedNextSkills}
        optionFilterProp={'label'}
        onChange={newValue => setSelectedNextSkills(newValue)}
        allowClear
        showSearch
      />
      <Button onClick={() => handleSaveNextSkills(record)}>Save</Button>
    </>
  )

  const renderSkillsForNextRole = (record, skillsLimit) =>
    selectedRunwayIdForSkills !== record.id ? skillsForNextRoleReadMode(record, skillsLimit) : skillsForNextRoleEditMode(record)

  const RenderFeedback = ({ feedback }) => (
    <div className={styleFeedback} title={feedback}>
      <span>{feedback}</span>
    </div>
  )

  const dataWithExtraInfo = useMemo(() => data?.runways?.map(getRunwayWithExtraInfo), [data])

  const { createSearchableColumn } = useSearchFilter()

  const columns = getColumns({
    createSearchableColumn,
    renderXProfiles,
    renderReserveButton,
    renderSkillsForNextRole,
    renderPartnerFeedback: ({ prcFeedback }) => <RenderFeedback feedback={prcFeedback} />,
    renderInternalFeedback: ({ internalFeedback }) => <RenderFeedback feedback={internalFeedback} />,
    skills,
  })

  return (
    <>
      <Modal open={showReserveModal} footer={null}>
        {selectedProfileData && (
          <ProfileProvider id={selectedProfileData.id}>
            <RoleCandidatesProvider profileId={selectedProfileData.id}>
              <ReservationModalContent
                profile={selectedProfileData}
                handleCloseModal={() => setShowReserveModal(false)}
                loading={loading}
                fetchData={fetchData}
              />
            </RoleCandidatesProvider>
          </ProfileProvider>
        )}
      </Modal>
      <Pagination simple={true} onChange={onPaginationChange} {...pagination} />
      <Table
        name="runwaysReservationsTable"
        showControls
        loading={loading}
        columns={columns}
        pagination={false}
        scroll={{ x: 1000, y: tableHeight }}
        size="small"
        dataSource={dataWithExtraInfo}
        rowKey="id"
        onChange={onTableChange}
        expandable={{
          expandedRowRender: record => <ExpandedRowContent
            record={record}
            renderXProfiles={renderXProfiles}
            renderReserveButton={renderReserveButton}
            renderSkillsForNextRole={renderSkillsForNextRole}
          />
        }}
      />
      <Pagination simple={true} onChange={onPaginationChange} {...pagination} />
    </>
  )
}

export default RunwayReservationsTable
