// extracted by mini-css-extract-plugin
export var breakpointSM = "680px";
export var breakpointXS = "340px";
export var breakpointXSM = "340px";
export var breakpoints = "'theme/breakpoints.module.css'";
export var brightNavyBlue = "#177DDC";
export var colors = "'theme/colors.module.css'";
export var editIconStyles = "RunwayReservationsTable-module--editIconStyles--99a3e";
export var feedbackStyles = "RunwayReservationsTable-module--feedbackStyles--4398e";
export var nextRoleSkillBlockStyles = "RunwayReservationsTable-module--nextRoleSkillBlockStyles--e6e85";
export var skillTagStyles = "RunwayReservationsTable-module--skillTagStyles--6bdd4";
export var xProfilesBlockStyles = "RunwayReservationsTable-module--xProfilesBlockStyles--f398a";