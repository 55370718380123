import React, { useState } from 'react'

import { ExportOutlined } from '@ant-design/icons'

import { Spin } from 'components/Spin'

import { createXProfileLink } from 'services/profile'

import type { RunwayReservation } from 'redesign/types/RunwayReservation'

import {
  xProfilesBlockStyles,
} from '../../RunwayReservationsTable.module.css'

export const XProfiles = ({ profileId }: RunwayReservation) => {
  const [loading, setLoading] = useState(false)
  const handleOpenXProfile = async (profileId: number) => {
    setLoading(true)
    try {
      const {
        data: { url }
      } = await createXProfileLink({ profile: profileId })
      window.open(url, '_blank')
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <div className={xProfilesBlockStyles}>
        <Spin />
      </div>
    )
  }

  return (
    <div
      className={xProfilesBlockStyles}
      onClick={() => handleOpenXProfile(profileId)}
    >
      <ExportOutlined />
      <span>Link</span>
    </div>
  )
}
