import type { AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import qs from 'qs'

import type { UseRunwaysCostsParams } from '../hooks/useRunwayCosts/useRunwayCosts.types'

import { getStartAndEndOfMonth } from 'utils/runway-helpers'

export const getRequestParams = (params: UseRunwaysCostsParams): AxiosRequestConfig => {
  const endAt = params.endAt ? `${params.endAt}` : dayjs().format('YYYY-MM')
  const monthDates = getStartAndEndOfMonth(endAt)

  const query = {
    populate: params.populate,
    _start: params._start,
    _limit: params._limit,
    _where: {
      _or: [
        {
          // Runways that started in the selected month
          startAt_gte: monthDates.startAt,
          startAt_lte: monthDates.endAt
        },
        {
          // Runways that started in a previous month and whose duration has not yet been defined
          startAt_lte: monthDates.endAt,
          endAt_null: true
        },
        {
          // Runways that end in the selected month
          endAt_gte: monthDates.startAt,
          endAt_lte: monthDates.endAt
        },
        {
          // Runways that started in a previous month and will ends in a future month
          startAt_lte: monthDates.endAt,
          endAt_gte: monthDates.endAt
        }
      ]
    }
  }

  return {
    params: query,
    paramsSerializer: param => qs.stringify(param as qs.ParsedQs)
  }
}
