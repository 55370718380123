import React from 'react'

import moment from 'moment'

import { Col } from '../../../Col'
import LabeledValue from '../../../LabeledValue'
import { ProfileNameColumn } from '../../../ProfileNameColumn'
import { Row } from '../../../Row'
import { QualifiedSkillsView } from '../QualifiedSkillsView'
import { StatusView } from '../StatusView'

import formatTimezoneOffset from '../../../../utils/format-timezone'

import { styleExpandedRow, styleExpandedCol } from './ExpandedRowContent.module.css'

const rowGutter = [16, 16]

const renderReservedOn = ({ prcReservedAt, prcPitchedAt }) => {
  const reservedAt = prcReservedAt || prcPitchedAt

  if (!reservedAt) {
    return null
  }

  return moment(reservedAt).format('DD/MM/YYYY')
}

const renderReservedRole = ({ partnerName, partnerRoleName }) => {
  if (!partnerRoleName) {
    return null
  }

  return `${partnerName}: ${partnerRoleName}`
}

const ExpandedRowContent = ({
  record,
  renderXProfiles,
  renderReserveButton,
  renderSkillsForNextRole
}) => (
  <>
    <Row gutter={rowGutter} className={styleExpandedRow}>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Name / Email"
          value={(
            <ProfileNameColumn profile={{
              profileId: record.profileId,
              profileFullName: record.profileFullName,
              profileEmail: record.profileEmail
            }} />
          )}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="X-Profiles"
          value={renderXProfiles ? renderXProfiles(record) : null}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Skills for Next Role"
          value={renderSkillsForNextRole ? renderSkillsForNextRole(record) : record.active}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Recently used skills"
          value={<QualifiedSkillsView parsedResumeData={record.parsedResumeData} />}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Status"
          value={<StatusView value={record.profileStatus} />}
        />
      </Col>
    </Row>
    <Row gutter={rowGutter} className={styleExpandedRow}>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Timezone"
          value={record.profileTimezoneOffset ? formatTimezoneOffset(record.profileTimezoneOffset, 'UTC') : null}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Job Types"
          value={record?.profilePreferredJobTypes?.split(',')?.join(', ')}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Runway Days Used"
          value={record.elapsed}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Runway Days Left"
          value={record.daysToEndingFromToday}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Reason for coming off last project"
          value={record.comingOffProjectReason}
        />
      </Col>
    </Row>
    <Row gutter={rowGutter} className={styleExpandedRow}>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Internal Summary"
          value={record.summary}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Reserved Role"
          value={renderReservedRole(record)}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Reserved On"
          value={renderReservedOn(record)}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Reserved Until"
          value={record.prcReservedUntil ? moment(record.prcReservedUntil).format('DD/MM/YYYY') : null}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Reserved Days"
          value={record.reservedDaysTotal}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Latest Partner"
          value={record.lastPartnerName}
        />
      </Col>
    </Row>
    <Row gutter={rowGutter} className={styleExpandedRow}>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Team"
          value={record.partnerTeamName}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Assignee"
          value={record.assigneeUsername}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Partner Feedback"
          value={record.prcFeedback}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Internal Feedback"
          value={record.internalFeedback}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Rate Concern"
          value={record.hasRateConcerns ? 'YES' : 'NO'}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="Start Date"
          value={record.startAt ? moment.utc(record.startAt).format('DD/MM/YYYY') : null}
        />
      </Col>
      <Col className={styleExpandedCol}>
        <LabeledValue
          label="End Date"
          value={record.endAt ? moment.utc(record.endAt).format('DD/MM/YYYY') : null}
        />
      </Col>
    </Row>
    <Row gutter={rowGutter} className={styleExpandedRow}>
      {!record.partnerRoleCandidateId && <Col className={styleExpandedCol}>
        <LabeledValue
          label="Actions"
          value={renderReserveButton ? renderReserveButton(record) : null}
        />
      </Col>}
    </Row>
  </>
)

export default ExpandedRowContent
