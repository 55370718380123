import { useContext } from 'react'

import { AuthContext } from '../../providers/AuthProvider'

const useAuth = () => {
  const authState = useContext(AuthContext)
  return authState
}

export default useAuth
