import React from 'react'

import { Tag } from 'components/Tag'

import { getSkillTagsLatest2Positions } from 'redesign/utils/skillUtils'

import type { QualifiedSkillsViewProps } from './QualifiedSkillsView.types'

import { skillTagStyles } from './QualifiedSkillsView.module.css'

const QualifiedSkillsView = ({ parsedResumeData, skillsLimit }: QualifiedSkillsViewProps) => {
  if (!parsedResumeData) {
    return null
  }

  return (
    <>
      {
        getSkillTagsLatest2Positions({ parsedResumeData, skillsLimit })
          .map(tagProp => (
            <Tag key={tagProp.id} color={tagProp.color} className={skillTagStyles}>{tagProp.name}</Tag>
          ))
      }
    </>
  )
}

export default QualifiedSkillsView
