import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { listRunwaysCostsV2 } from 'services/runways'

import { getRequestParams } from '../../utils/costsUtils'

import type {
  UseRunwaysCostsParams,
  UseRunwaysCostsReturn
} from './useRunwayCosts.types'

export const useRunwayCosts = (
  params: UseRunwaysCostsParams
): UseRunwaysCostsReturn => {
  const {
    data: runwayCostsData,
    isLoading,
    isSuccess,
    refetch
  } = useQuery({
    queryKey: ['runwaysCosts', params],
    queryFn: () =>
      listRunwaysCostsV2(
        getRequestParams({
          populate: ['profile.profileStatus'],
          ...params
        })
      )
  })

  const data = useMemo(() => runwayCostsData?.data || [], [runwayCostsData])

  return {
    data,
    isLoading,
    isSuccess,
    refetch
  }
}
