import React from 'react'

import * as tagStyles from 'components/ProfileStatusTag/StatusTag.module.css'
import { Tag } from 'components/Tag'

import type { ProfileStatus } from 'redesign/components/ProfileStatuses'
import { PROFILE_STATUSES } from 'redesign/components/ProfileStatuses'

export interface StatusViewProps {
  value: ProfileStatus
}

export const StatusView = ({ value }: StatusViewProps) => {
  const statusText = value ?? PROFILE_STATUSES.ready.value

  return (
    <Tag key={statusText} className={tagStyles[statusText]}>
      {PROFILE_STATUSES[statusText].icon} {PROFILE_STATUSES[statusText].text}
    </Tag>
  )
}
