import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { countRunways } from 'services/runways'

import type { UseRunwaysCountProps, UseRunwaysCountReturn } from './useRunwaysCount.types'

export const useRunwaysCount = (params: UseRunwaysCountProps): UseRunwaysCountReturn => {
  const { data: runwayTotalData, isLoading: isTotalLoading, isSuccess: isTotalSuccess, refetch: refetchTotal } = useQuery<AxiosResponse<number>, AxiosError>({
    queryKey: ['runwaysTotal', params],
    queryFn: () => countRunways({
      params: {
        ...params
      }
    }),
  })

  const dataTotal = useMemo(() => runwayTotalData?.data || 0, [runwayTotalData])

  return {
    dataTotal,
    isTotalLoading,
    isTotalSuccess,
    refetchTotal,
  }
}
