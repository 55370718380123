import React from 'react'

import { formatCosts } from 'utils/runway-helpers'

import { containerStyles } from './RunwayTotalProjectedCosts.module.css'

interface RunwayTotalProjectedCostsProps {
  totalCosts: number
}

export default function RunwayTotalProjectedCosts ({ totalCosts }: RunwayTotalProjectedCostsProps) {
  const costs = formatCosts(totalCosts)

  return (
    <div data-testid="runway-total-projected-costs" className={containerStyles}>
      <b>Total projected monthly cost:</b> {costs}
    </div>
  )
}
