import * as React from 'react'

import moment from 'moment'

import {
  getDateInReadableFormat,
  getInDaysFormat
} from '../../utils/runway-helpers'

export default function RunwayStartAtColumn ({ startAt }) {
  const today = moment().format('YYYY-MM-DD')
  const daysToStartFromToday = moment(startAt).diff(today, 'days')

  return (
    <>
      <div>{getDateInReadableFormat(startAt)}</div>
      {daysToStartFromToday > 0 && (
        <div>(Starting in {getInDaysFormat(daysToStartFromToday)})</div>
      )}
      {daysToStartFromToday === 0 && <div>(Starting today)</div>}
    </>
  )
}
