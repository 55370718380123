import * as React from 'react'

import {
  getDateInReadableFormat,
  getElapsedDaysOutOfDurationFormat
} from 'utils/runway-helpers'

import { textStyles, dividerStyles } from './RunwayElapsedInfo.module.css'

type RunwayElapsedInfoProps = {
  runway: {
    startAt: string
    endingDate: string | null
    elapsed: number
    endingDuration: number
    description: string
  }
}

export const RunwayElapsedInfo = ({ runway }: RunwayElapsedInfoProps) => (
  <>
    <p className={textStyles}>
      <b>Start:</b> {getDateInReadableFormat(runway.startAt)}
    </p>

    <p className={textStyles}>
      <b>End:</b>{' '}
      {runway.endingDate ? getDateInReadableFormat(runway.endingDate) : `Not defined yet`}
    </p>

    <div className={dividerStyles}></div>

    <p className={textStyles}>
      {getElapsedDaysOutOfDurationFormat(
        runway.elapsed,
        runway.endingDuration
      )}
    </p>

    {runway.description && <p className={textStyles}>({runway.description})</p>}
  </>
)
