import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { listRunwayReservationsV2 } from 'services/runways'

import type {
  UseRunwaysReservationsParams,
  UseRunwaysReservationsReturn
} from './useRunwayReservations.types'

export const useRunwayReservations = (
  params: UseRunwaysReservationsParams
): UseRunwaysReservationsReturn => {
  const {
    data: runwayReservationsData,
    isLoading,
    isSuccess,
    refetch
  } = useQuery({
    queryKey: ['runwaysReservations', params],
    queryFn: () => listRunwayReservationsV2({
      params: {
        populate: [
          'profile.profileStatus',
        ],
        ...params
      }
    }),
  })

  const data = useMemo(() => runwayReservationsData?.data || [], [runwayReservationsData])

  return {
    data,
    isLoading,
    isSuccess,
    refetch
  }
}
